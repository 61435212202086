import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUserFunc, logoutFunc } from '../states/loginState';
import { getAllAnnouncementsByIdOwnerFunc, clearMediationData } from '../states/storeState';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { verifyMailFunc } from '../states/signinState';
import CategoriesPreferences from './CategoriesPreferences';
import AccountEditAnagraphic from './AccountEditAnagraphic';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import decodeToken from '../functions/decodeToken';
import { stripeUnsubscribeFunc } from '../states/unsubscribeState';
import packagesJSON from '../assets/JSON/packages.json';
import { createSlotsFunc } from '../states/slotState';
import JSEncrypt from 'jsencrypt';
import superuserMails from '../assets/JSON/superusers.json';
import serverResponseManagement from '../functions/serverResponseManagement';
import { updateTutorialFunc } from '../states/tutorialState';
import TutorialAccount from './_Tutorials/TutorialAccount';
import { InputGroup } from 'react-bootstrap';
import Logo from '../assets/Graphics/mainLogo.svg';
import { NativeBiometric } from "@capgo/capacitor-native-biometric";
import { Capacitor } from '@capacitor/core';


const _Account = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.login.userData);
  const loading = useSelector((state) => state.signin.loading);
  const isLoadingTutorial = useSelector((state) => state.tutorial.isLoading);
  const [isResended, setIsResended] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditingAnagraphic, setIsEditingAnagraphic] = useState(false);
  const [weeklyQnt, setWeeklyQnt] = useState(0);
  const [monthlyQnt, setMonthlyQnt] = useState(0);
  const [subscriptionPackage, setSubscriptionPackage] = useState(1);
  const [slotTypeSelector, setSlotTypeSelector] = useState(1);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const weeklyPrice = 5.99;
  const monthlyPrice = 12.99;
  const accountCardRef = useRef();
  const verifyMailError = localStorage.getItem("verifyMailError");
  const [tutorialAccount, setTutorialAccount] = useState(false);
  const [repeatTutorialObj, setRepeatTutorialObj] = useState({ bool: false, market: 1, chat: 1, home: 1, favourites: 1 });
  const tutorialData = useSelector((state) => state.tutorial.tutorialData);
  const scrollRef = useRef();
  const token = localStorage.getItem("token");
  const tkn = decodeToken(dispatch);

  const deleteMyCredentials = async () => {
    if (Capacitor.isNativePlatform()) {
      await NativeBiometric.deleteCredentials({
        server: "...",
      })
    }
  };

  const logOut = () => {
    dispatch(logoutFunc())
      .then((res) => {
        if (res.payload && res.payload.statusCode === 200) {
          localStorage.clear();
          dispatch({ type: "LOGOUT" });
          navigate("/login");
          deleteMyCredentials()
        }
      })
  };

  useEffect(() => {

    if (token) {
      dispatch(getSingleUserFunc({ id: tkn.id, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch)
        })
      dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch)
        });

    } else {
      logOut()
    }
  }, []);

  useEffect(() => {
    if (userData && userData.length > 0 && superuserMails.includes(userData[0].email)) {
      setIsSuperuser(true)
    }
  }, [userData]);

  const resendVerification = () => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);

    const encryptedEmail = encrypt.encrypt(userData[0].email);
    const encryptedCompanyName = encrypt.encrypt(userData[0].companyName);
    const encryptedVerifyCode = encrypt.encrypt(`${userData[0].id}-${userData[0].verifyCode}`);

    dispatch(verifyMailFunc({ payload: { email: encryptedEmail, companyName: encryptedCompanyName, verifyCode: encryptedVerifyCode }, token }))
      .then((res) => {
        serverResponseManagement(res, navigate, dispatch);
        if (res.payload && res.payload.statusCode === 200) {
          setIsResended(true);
          localStorage.removeItem("verifyMailError");
        }
        if (res.payload && res.payload.statusCode === 400) {
          setErrorMessage(res.payload.message);
          localStorage.setItem("verifyMailError", res.payload.message);
        }
      })
  };

  const triggerHolding = () => {
    setTimeout(() => {
      setIsResended(false);
    }, "40000")
  };

  //Slots
  const increaseCounter = (input) => {
    if (input === "weekly") {
      setWeeklyQnt(weeklyQnt + 1)
    } else {
      setMonthlyQnt(monthlyQnt + 1)
    }
  };

  const decreaseCounter = (input) => {
    if (input === "weekly") {
      if (weeklyQnt > 0) { setWeeklyQnt(weeklyQnt - 1) }
    } else {
      if (monthlyQnt > 0) { setMonthlyQnt(monthlyQnt - 1) }
    }
  };

  /* useEffect(() => { //unsubscribe stripe timer
    if (userData[0]) {
      if (userData[0].unsubscribeTimer && userData[0].idBilling) {
        const seconds = (new Date().getTime() - new Date(userData[0].unsubscribeTimer).getTime()) / 1000;
        const myRemainingTime = Math.round((2592000 - seconds) / 360) / 10;
        if (myRemainingTime <= 0) {
          dispatch(stripeUnsubscribeFunc({ idBilling: userData[0].idBilling, token: localStorage.getItem("token") }))
            .then((res) => {
              serverResponseManagement(res, navigate, dispatch);
              if (res.payload && res.payload.statusCode === 200) {
                dispatch(updateAccountFunc({ payload: { id: userData[0].id, unsubscribeTimer: "", idBilling: "", package: 0 }, token: localStorage.getItem("token") }))
                  .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                      setTimeout(() => {
                        window.location.reload()
                      }, 2000)
                    }
                  })
              }
            })
        }
      }
    }
  }, [userData]) */

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [loading]);

  useEffect(() => {
    if (isEditingAnagraphic) {
      accountCardRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isEditingAnagraphic]);

  //Tutorial Functions
  const updateTutorial = () => {
    dispatch(updateTutorialFunc({ payload: { account: 0 }, token }))
      .then((res) => {
        if (res.payload.statusCode === 200) {
          window.location.reload()
        }
      })
  };

  const repeatTutorial = () => {
    console.log(repeatTutorialObj);
    dispatch(updateTutorialFunc(
      {
        payload: { market: repeatTutorialObj.market ? 1 : 0, chat: repeatTutorialObj.chat ? 1 : 0, favourites: repeatTutorialObj.favourites ? 1 : 0, home: repeatTutorialObj.home ? 1 : 0 },
        token
      }
    ))
      .then((res) => {
        if (res.payload.statusCode === 200) {
          window.location.reload()
        }
      })
  };

  useEffect(() => {
    if (tutorialData && !tutorialData.market && !tutorialData.favourite && !tutorialData.home && !tutorialData.chat && tutorialData.account) {
      setTutorialAccount(true)
    }
  }, [tutorialData]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

    if (userData[0] && !userData[0].accountActive) {
      setTimeout(() => {/* userData[0].accountActive */
        document.getElementById("activate")?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500)
    }
  }, [userData]);



  return (
    <div className='pb-5 myBgAbsBlack' ref={scrollRef}>

      <div className='position-relative'>
        {
          tutorialAccount ?
            <div className='position-fixed start-0 d-flex justify-content-center w-100 myZindex4 myOverflowY myMaxVh100 py-5 mt-4'>
              <div className='text-light border border-secondary shadow myBgTransparentVeryHigh top-0 m-3 mt-4 rounded-5 p-3 py- text-center h-100 d-flex flex-column gap-5 myMaxW500'>
                <TutorialAccount step={0} />
                <Button className='rounded-5 w-100' disabled={isLoadingTutorial ? true : false} onClick={updateTutorial}>Ok, I got it! {isLoadingTutorial ? <Spinner variant='light' animation='border' size='sm' /> : null}</Button>
              </div>
            </div>
            : null
        }
      </div>

      {
        !repeatTutorialObj.bool ?
          <div className='d-flex justify-content-center pt-4'>
            <Button className='rounded-5 px-4' variant='dark' onClick={() => { setRepeatTutorialObj({ ...repeatTutorialObj, bool: true }) }}>Repeat Tutorial</Button>
          </div>
          : null
      }

      {
        repeatTutorialObj.bool ?
          <div className='d-flex justify-content-center'>
            <div className='myMaxW500 p-3 px-2 pt-4 text-light w-100'>
              <div className='d-flex gap-2'>
                <InputGroup className="mb-3 d-flex gap-2 align-items-center rounded-5 border border-dark">
                  <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={repeatTutorialObj.market} onChange={() => { setRepeatTutorialObj({ ...repeatTutorialObj, market: !repeatTutorialObj.market }) }} aria-label="Checkbox for following text input" />
                  Market <img className='m-0' style={{ width: "18px" }} src={Logo} />
                </InputGroup>
                <InputGroup className="mb-3 d-flex gap-2 align-items-center rounded-5 border border-dark">
                  <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={repeatTutorialObj.home} onChange={() => { setRepeatTutorialObj({ ...repeatTutorialObj, home: !repeatTutorialObj.home }) }} aria-label="Checkbox for following text input" />
                  Home <i className='bi bi-house-fill'></i>
                </InputGroup>
              </div>
              <div className='d-flex gap-2'>
                <InputGroup className="mb-3 d-flex gap-2 align-items-center rounded-5 border border-dark">
                  <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={repeatTutorialObj.favourites} onChange={() => { setRepeatTutorialObj({ ...repeatTutorialObj, favourites: !repeatTutorialObj.favourites }) }} aria-label="Checkbox for following text input" />
                  {window.innerWidth < 350 ? "Favou.." : "Favourites"} <i className='bi bi-heart-fill '></i>
                </InputGroup>
                <InputGroup className="mb-3 d-flex gap-2 align-items-center rounded-5 border border-dark">
                  <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={repeatTutorialObj.chat} onChange={() => { setRepeatTutorialObj({ ...repeatTutorialObj, chat: !repeatTutorialObj.chat }) }} aria-label="Checkbox for following text input" />
                  Chat <i className='bi bi-chat-dots-fill'></i>
                </InputGroup>
              </div>
              <div className='d-flex justify-content-center w-100'>
                <Button className='rounded-5 px-3 py-1' onClick={repeatTutorial} disabled={isLoadingTutorial ? true : false}>Apply {isLoadingTutorial ? <Spinner variant='light' animation='border' size='sm' /> : null}</Button>
              </div>
            </div>
          </div>
          : null
      }

      {
        isSuperuser ?
          <div className='insideNavbar d-flex justify-content-center gap-3 flex-wrap p-3 pt-5'>
            {/* <div><Link className='noLinkStyle noStyle' to={"/invoices"}><li> <h6><i className="bi bi-credit-card-2-back-fill me-2"></i>Invoices</h6> </li></Link></div>
            <div><Link className='noLinkStyle noStyle' to={"/contactus"}><li> <h6><i className="bi bi-question-circle-fill me-2"></i>Support</h6> </li></Link></div> */}
            <div onClick={() => dispatch(clearMediationData())}><Link className='noLinkStyle noStyle' to={"/pendingannouncements"}><li> <h6>Pending Announcements</h6> </li></Link></div>
            <div onClick={() => dispatch(clearMediationData())}><Link className='noLinkStyle noStyle' to={"/rejectedannouncements"}><li> <h6>Rejected Announcements</h6> </li></Link></div>
          </div>
          : null
      }
      {
        userData && userData.length > 0 ?
          <div className='position-relative pt-4'>

            <div>
              <h1 className='fw-bold text-light text-center pt-3'><i className="bi bi-person-circle"></i> Account</h1>
              <div className='d-flex justify-content-center flex-wrap align-items-center gap-3 mx-1 my-5 mt-4'>

                {/* accout card */}
                <div className="d-flex align-items-center justify-content-center myMaxW632" ref={accountCardRef}>
                  <div className={`rounded-5 p-4 py-5 ${userData[0].package ? "myDarkGradient" : "myDarkGradient"} ${isSuperuser ? "borderSuperuser" : ""} shadow`}>
                    {
                      userData[0].verifiedAccount === 0 ?
                        null :
                        <div className='position-relative text-center'>
                          {
                            isSuperuser ?
                              null
                              :
                              <div className='position-absolute activeUserIcon d-flex justify-content-center align-items-center w-100'>{/*layer per ottener il check bianco */}
                                <h6 className='bg-light text-light mt-3'>
                                  oo
                                </h6>
                              </div>
                          }
                          {
                            isSuperuser ?
                              <div className='position-absolute activeUserIcon d-flex justify-content-center align-items-center w-100 gap-1 ' style={{ top: "-87px" }}>
                                <i className="bi bi-suit-diamond-fill myDeepVioletColor myFontSize50px" ></i>
                              </div>
                              :
                              <div className='position-absolute activeUserIcon d-flex justify-content-center align-items-center w-100 gap-1 ' style={{ top: "-75px" }}>
                                <i className="bi bi-patch-check-fill myAquaColor"></i>
                              </div>
                          }
                        </div>
                    }

                    {
                      isEditingAnagraphic ?
                        <AccountEditAnagraphic userData={userData[0]} isInvoiceAnagraphic={false} />
                        :
                        <div>
                          <h2 className='me-2'>
                            {
                              userData[0].package ?
                                userData[0].package === 1 ?
                                  <i className="bi bi-lightning-charge-fill myYellowColor me-1"></i>
                                  : userData[0].package === 2 ?
                                    <i className="bi bi-droplet-fill myAquaColor me-1"></i>
                                    : <i className="bi bi-fire myFucsiaRed me-1"></i>
                                : null
                            }
                            {userData[0].companyName}
                          </h2>
                          <hr />
                          <div className={isSuperuser ? "myDeepVioletColor" : userData[0].manufacturer ? "myYellowColor" : "myAquaColor"}><h1 className='fw-bold'>{isSuperuser ? "S" : userData[0].manufacturer ? "M" : "D"}</h1></div>
                          <div className='d-flex flex-column justify-content-center gap-3 mb-3 ' style={{ width: "84vw", maxWidth: "400px" }}>
                            <div className='mt-3' style={{ overflowWrap: "break-word" }}>
                              <i className="bi bi-envelope-at-fill me-2"></i>
                              {
                                userData[0].accountActive ?
                                  <i className="bi bi-check-lg myChatColor me-2"></i>
                                  : <i className="bi bi-x-lg text-danger me-2"></i>
                              }
                              <h6 className='fw-light'>{userData[0].email}</h6>
                            </div>
                            {userData[0].country ? <h6 className='fw-light'><i className="bi bi-geo-alt-fill me-2"></i>{`${userData[0].city} (${userData[0].state ? userData[0].state : ""}) - ${userData[0].country} `}</h6> : null}
                            {userData[0].phone && userData[0].phone.split("-")[1] ? <h6 className='fw-light'><i className="bi bi-phone-fill me-2"></i>{userData[0].phone}</h6> : null}
                          </div>
                        </div>
                    }


                    <div>
                      <hr className='my-4 w-100' />
                      <div className='d-flex justify-content-center'>
                        <Button className='rounded-5 btn btn-light fw-bold myBgLightGray text-dark '>
                          <i className={`bi ${isEditingAnagraphic ? "bi-caret-left-fill" : "bi-pencil-fill"}`} onClick={() => setIsEditingAnagraphic(!isEditingAnagraphic)}>{isEditingAnagraphic ? " back" : " change"}</i>
                        </Button>
                      </div>
                    </div>

                  </div>

                </div>



                {/*
                  userData[0].package ?
                    null
                    :
                    <div className='d-flex justify-content-center'>
                      <div className='d-flex myBgDarkGray myMaxW632 m-2 align-items-center flex-column mx-2 shadow rounded-4'>
                        <div className='d-flex w-100 text-center rounded-top-4'>
                          <div className={`myWidth33Percent p-2 pb-1 myCursor text-light rounded-top-4 ${subscriptionPackage === 1 ? "myBgDark" : ""}`} onClick={() => setSubscriptionPackage(1)}>
                            <i className="bi bi-lightning-charge-fill myYellowColor"></i> Premium
                          </div>
                          <div className={`myWidth33Percent p-2 pb-1 myCursor text-light rounded-top-4 ${subscriptionPackage === 2 ? "myBgDark" : "shadow"}`} onClick={() => setSubscriptionPackage(2)}>
                            <i className="bi bi-droplet-fill myAquaColor"></i> Business
                          </div>
                          <div className={`myWidth33Percent p-2 pb-1 myCursor text-light rounded-top-4 ${subscriptionPackage === 3 ? "myBgDark" : ""}`} onClick={() => setSubscriptionPackage(3)}>
                            <i className="bi bi-fire myFucsiaRed"></i> Seller
                          </div>
                        </div>
                        <div className={`d-flex align-items-center flex-column p-4 myDarkGradient rounded-bottom-4 ${subscriptionPackage === 1 ? "rounded-end-4" : subscriptionPackage === 2 ? "rounded-top-4" : "rounded-start-4"}`}>
                          {
                            packagesJSON.map((el, index) => {
                              if (el.id === subscriptionPackage) {
                                return (
                                  <div key={`account0-${index}`}>
                                    <div className={`${el.color} d-flex align-items-baseline justify-content-center gap-1 mt-2`}>
                                      <h1 className='fw-light'>{el.price}€</h1>
                                      <p>/month</p>
                                    </div>
                                    <ul className='mt-3 text-light' style={{ lineHeight: "2rem" }}>
                                      <li>Unlimited <b>Chat Contacts</b></li>
                                      <li><b>{el.weekly} Weekly</b> announcement simultaneously</li>
                                      {el.monthly ? <li><b>{el.monthly} Monthly</b> announcement {el.monthly !== 1 ? "simultaneously" : ""}</li> : null}
                                      <li>Up to <b>{el.dailyAnnouncement}</b> daily announcement</li>
                                    </ul>
                                    <div className='d-flex justify-content-center'>
                                      <Link to={el.link}>
                                        <button className='text-center mb-1 btn btn-dark p-2 px-4 rounded-5' style={{ border: `1px solid ${el.colorHex}` }}>
                                          <h4 className='fw-light text-light'><i className={`bi ${el.icon} ${el.color}`}></i> upgrade {el.name}</h4>
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }

                        </div>
                      </div>
                    </div>
                */}

                {/* <div className='myDarkGradient text-light p-4 rounded-5 myMaxW632 m-2'>
                  <h4 className='mb-4 fw-light text-center m-3'>Buy a Slot to BOOST your visibility!</h4>
                  <div className='d-flex flex-column gap-3'>
                    <div className='d-flex justify-content-center gap-3 mt-3'>
                      <h5 className={`myCursor text-center text-dark mb-1 p-1 px-3 rounded-5 ${slotTypeSelector === 1 ? "myGradientWeekly" : "myBgDarkGray"}`} onClick={() => { setSlotTypeSelector(1); setMonthlyQnt(0) }}><i className="bi bi-7-circle-fill me-2"></i>Weekly</h5>
                      <h5 className={`myCursor text-center text-dark mb-1 p-1 px-3 rounded-5 ${slotTypeSelector === 2 ? "myGradientMonthly" : "myBgDarkGray"}`} onClick={() => { setSlotTypeSelector(2); setWeeklyQnt(0) }}><i className="bi bi-moon-stars-fill me-2"></i>Monthly</h5>
                    </div>
                    {
                      slotTypeSelector === 1 ?
                        <div>
                          <div className='d-flex justify-content-between align-items-center px-2 pt-2 rounded-5 myBgDark'>
                            <i className="bi bi-dash display-5 myCursor" onClick={() => decreaseCounter("weekly")}></i>
                            <h1>{weeklyQnt}</h1>
                            <i className="bi bi-plus display-5 myCursor" onClick={() => increaseCounter("weekly")}></i>
                          </div>
                          <div className='d-flex justify-content-between px-4'>
                            <h6 className='mt-2 text-center fw-light'>{weeklyPrice}€/week</h6>
                            <h4 className='mt-2 text-center fw-normal'>Total: {Math.floor((weeklyQnt * weeklyPrice) * 100) / 100}€</h4>
                          </div>
                        </div>
                        :
                        <div>
                          <div className='d-flex justify-content-between align-items-center px-2 pt-2 rounded-5 myBgDark'>
                            <i className="bi bi-dash display-5 myCursor" onClick={() => decreaseCounter("monthly")}></i>
                            <h1>{monthlyQnt}</h1>
                            <i className="bi bi-plus display-5 myCursor" onClick={() => increaseCounter("monthly")}></i>
                          </div>
                          <div className='d-flex justify-content-between px-4'>
                            <h6 className='mt-2 text-center fw-light'>{monthlyPrice}€/month</h6>
                            <h4 className='mt-2 text-center fw-normal'>Total: {Math.floor((monthlyQnt * monthlyPrice) * 100) / 100}€</h4>
                          </div>
                        </div>

                    }

                  </div>
                  <div className='d-flex justify-content-center mt-2'>
                    <Button className='mt-4 rounded-5 px-4' disabled={!monthlyQnt && !weeklyQnt} onClick={() => { navigate(`/buyslot/${slotTypeSelector === 1 ? "WEEKLY" : "MONTHLY"}/${slotTypeSelector === 1 ? weeklyQnt : monthlyQnt}`) }  }> //dispatch(createSlotsFunc({ payload: { idOwner: decodedTkn.id, weeklyQnt: 2, monthlyQnt: 0 }, token: localStorage.getItem("token") }))
                      {
                        weeklyQnt === 0 && monthlyQnt === 0 ?
                          "Buy Slot"
                          : slotTypeSelector === 1 ?
                            `Buy ${weeklyQnt} Slots`
                            : `Buy ${monthlyQnt} Slots`
                      }
                    </Button>
                  </div>
                </div>
                */}
              </div>

              {
                verifyMailError ?
                  <div className='d-flex justify-content-center'><div className='text-center text-danger myMaxW500 mb-5 px-2'><i className="bi bi-exclamation-circle display-1"></i><h6>{verifyMailError}</h6></div></div>
                  : null
              }

              {
                userData[0].accountActive ?
                  null
                  :
                  <div className='d-flex justify-content-center' id='activate'>
                    <div className='bg-danger text-light myMaxW700 p-3 pt-4 text-center'>
                      <div>
                        <h3 className='mb-3'>Your account is not active!</h3>
                        You should have received an email containing a link. Click on it to activate your account. If you haven't received an email from us, click the "Resend" button below to send another verification email. It is normal to wait at least 2-3 min.
                      </div>
                      {/* resending verification mail logic */}
                      <Button className='mt-3' disabled={isResended && !errorMessage ? true : false} variant="dark" onClick={() => { resendVerification(); triggerHolding() }}>
                        {
                          loading ?
                            <i>Sending <Spinner className='ms-1' animation="grow" size="sm" /></i>
                            : isResended && !errorMessage ?
                              <i className="bi bi-send-check-fill text-success"> Resended</i>
                              :
                              !isResended && !errorMessage ?
                                <i className="bi bi-send-fill"> Resend</i>
                                : <i className="bi bi-send-x-fill text-danger"> Not sended</i>
                        }
                      </Button>

                      {
                        isResended ?
                          <div>
                            <div className='mt-3 text-dark fw-bold'><i>check your email <Spinner className='ms-1' animation="grow" size="sm" /></i></div>
                            <div className='w-100 px-5 mt-2'>
                              <p className='text-light mb-1'>next try in:</p>
                              <div className='border rounded mx-3'>
                                <div className={`bg-light rounded ${isResended ? "percentageBar2" : null}`} style={{ height: "6px" }}></div>
                              </div>
                            </div>
                          </div>
                          : null
                      }


                      {isResended ? <h6 className='mt-3'>* If you don't receive any email check out first if your email is correct.</h6> : null}
                    </div>
                  </div>
              }

              {
                errorMessage ?
                  <div className='d-flex justify-content-center mt-1'>
                    <div className='bg-danger text-light myMaxW700 p-3 text-center'>
                      <h6 className='fw-bold mb-1'>The reason why the email cannot be sent: </h6>
                      {errorMessage}
                    </div>
                  </div>
                  : null
              }

              {
                <CategoriesPreferences userData={userData[0]} />
              }

              <div className='px-4 pb-5 pt-3 d-flex justify-content-center'>
                <Button className='w-100 myMaxW700' variant='danger'>
                  <Link className='noLinkStyle noStyle' to={"/"}><li> <h5 onClick={logOut}>Logout <i className="bi bi-box-arrow-right ms-1"></i></h5> </li></Link>
                </Button>
              </div>

              <hr className='bg-secondary' />

              <div className='text-light px-3 text-center my-4'>
                Contact us at: <b>support@mystocker.com</b>
              </div>

              <div className='d-flex justify-content-center'>
                <div className='d-flex flex-column justify-content-center gap-3'>
                  <Link to={'/privacy'} target='_blank' className='myYellowColor'>Privacy Policy</Link>
                  <Link to={'/childsafety'} target='_blank' className='myYellowColor'>Child Safety Policy</Link>
                  <Link to={'/deleteaccount'} className='text-danger'>Delete Account</Link>
                </div>
              </div>


            </div>

          </div>
          :
          <div className='d-flex justify-content-center pt-5'><Spinner animation='border' variant='secondary' /></div>

      }

    </div >
  )

}

export default _Account