import React, { useEffect, useState } from 'react';
import { getSingleDisclaimer } from '../../states/disclaimerState';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner, InputGroup } from 'react-bootstrap';
import { updateAccountFunc } from '../../states/signinState';
import { updateUserData } from '../../states/loginState';
import { Link } from 'react-router';

const DisclaimerMain = () => {

    const dispatch = useDispatch();
    const token = localStorage.getItem("token");

    const disclaimer = useSelector((state) => state.disclaimers.singleDisclaimer);
    const isLoading = useSelector((state) => state.disclaimers.isLoading);
    const loading = useSelector((state) => state.signin.loading);
    const userData = useSelector((state) => state.login.userData);
    const [privacyPolicyAgree, setPrivacyPolicyAgree] = useState(false);
    const [imOver18, setImOver18] = useState(false);
    const [childSafety, setChildSafety] = useState(false);

    const legalAgreementUpdate = () => {
        dispatch(updateAccountFunc({ payload: { legalAgreement: 1 }, token }));
        dispatch(updateUserData({ ...userData[0], legalAgreement: 1 }))
    }

    useEffect(() => {
        dispatch(getSingleDisclaimer({ id: 1, token }))
    }, [])

    return (

        <div className='position-fixed start-0 d-flex justify-content-center w-100 myZindexMax myBgDark222 myVh100 myOverflowY pb-3 pt-2'>
            <div className='text-light border border-dark shadow myBgDark myOverflowY top-0 m-3 w-100 p-3 pb-4 mb-5 myMaxW500'>
                <h3 className='mb-2 text-center mb-4'>Terms of Service – Security Policy, Protection of Minors, and Publication of Products and Services</h3>
                {
                    isLoading ?
                        <div className='d-flex justify-content-center mt-5'><Spinner size="sm" /></div>
                        :
                        <div>
                            {
                                disclaimer && disclaimer.text && disclaimer.text.split('$$000000$$').map((el) => {
                                    if (el.split('££111111££').length > 1) {
                                        return (
                                            <>
                                                <h4 className='my-2'>{el.split('££111111££')[0]}</h4>
                                                <ul className='mb-3'>
                                                    {
                                                        el.split('££111111££')[1].split("* ").map((item, index) => {
                                                            if (index) {
                                                                return <li>{item}</li>
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </>
                                        )
                                    } else {
                                        return <div className='my-3'>{el}</div>
                                    }
                                })
                            }
                        </div>
                }

                <div className='my-4'>
                    <InputGroup className="my-3 py-1 d-flex flex-column align-items-start gap-3 rounded-5">
                        <div className='d-flex justify-content-center align-items-center gap-2 align-items-center'>
                            <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={privacyPolicyAgree} onChange={() => { setPrivacyPolicyAgree(!privacyPolicyAgree) }} aria-label="Checkbox for following text input" />
                            <p className='m-0'>I declare that I have read and understood the <Link to={'/privacy'} className='myYellowColor' target='_blank'>Privacy Policy</Link></p>
                            {privacyPolicyAgree ? null : <i className="bi bi-dot myFucsiaRed display-6"></i>}
                        </div>
                        <div className='d-flex justify-content-center align-items-center gap-2 align-items-center'>
                            <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={privacyPolicyAgree} onChange={() => { setChildSafety(!childSafety) }} aria-label="Checkbox for following text input" />
                            <p className='m-0'>I declare that I have read and understood the <Link to={'/childsafety'} className='myYellowColor' target='_blank'>Child Safety Policy</Link></p>
                            {childSafety ? null : <i className="bi bi-dot myFucsiaRed display-6"></i>}
                        </div>
                        <div className='d-flex justify-content-center align-items-center gap-2 align-items-center'>
                            <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={privacyPolicyAgree} onChange={() => { setImOver18(!imOver18) }} aria-label="Checkbox for following text input" />
                            <p className='m-0'><b>I confirm that I am of legal age. I understand terms of service.</b></p>
                            {imOver18 ? null : <i className="bi bi-dot myFucsiaRed display-6"></i>}
                        </div>
                    </InputGroup>
                </div>

                {isLoading ? null : <Button className='rounded-5 w-100 my-3' disabled={!loading && privacyPolicyAgree && childSafety && imOver18 ? false : true} onClick={() => { legalAgreementUpdate() }}>Accept {loading ? <Spinner size='sm' /> : null}</Button>}
            </div>
        </div>

    )
}

export default DisclaimerMain