import { React, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { postSigninFunc, verifyMailFunc } from '../states/signinState';
import { postLoginFunc } from '../states/loginState';
import { useNavigate, Link } from 'react-router';
import Spinner from 'react-bootstrap/Spinner';
import phoneCodes from '../assets/JSON/countryPhoneCodes.json';
import { setDistributionArea, delDistributionArea, setSellingAreaExcluded, delSellingAreaExcluded, clearSellingAreaExcluded, setSellingAreaSelected, delSellingAreaSelected, clearSellingAreaSelected } from '../states/generalState';
import { getCitiesFunc, searchCity, clearSearchCity, searchCountry } from '../states/geonamesState';
import JSEncrypt from 'jsencrypt';
import serverResponseManagement from '../functions/serverResponseManagement';
import DisclaimerSignin from './_Disclaimers/DisclaimerSignin';


const _Signin = () => {

    const [typeOfJob, setTypeOfJob] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [pssw, setPssw] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [verifyCode, setVerifyCode] = useState((Math.random() + 1).toString(18).substring(2))

    const [firstSendTry, setFirstSendTry] = useState(true);
    const [serverResponse, setServerResponse] = useState("");
    const formOk = companyName && email && pssw && country && city && typeOfJob;

    const [addArea, setAddArea] = useState(false);
    const [sellWholeWorld, setSellWholeWorld] = useState(true);
    const [excludeCountry, setExcludeCountry] = useState(true);
    const [step, setStep] = useState(0);
    const [openInfo, setOpenInfo] = useState(false);
    const [psswVisible, setPsswVisible] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signinLoading = useSelector((state) => state.signin.loading);
    const distributionAreaISO = useSelector((state) => state.general.distributionAreaISO);
    const sellingAreaExcludedISO = useSelector((state) => state.general.sellingAreaExcludedISO);
    const sellingAreaSelectedISO = useSelector((state) => state.general.sellingAreaSelectedISO);
    const allCitiesFiltered = useSelector((state) => state.geonames.allCitiesFiltered);
    const countryFiltered = useSelector((state) => state.geonames.countryFiltered);
    const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
    const [sellExclCountryDropdownOpen, setSellExclCountryDropdownOpen] = useState(false);
    const [privacyPolicyAgree, setPrivacyPolicyAgree] = useState(false);
    const [imOver18, setImOver18] = useState(false);
    const [childSafety, setChildSafety] = useState(false);

    const token = localStorage.getItem("token");

    //inputs validation
    const specialCharacter = ["!", "#", "$", "%", "&", "@", "<", ">", "="];
    const isEmailValid = email.includes("@") && email.includes(".") && email.length > 6;
    const isPsswValid = pssw.split("").some((x) => specialCharacter.includes(x)) && pssw.length > 7;
    const [isCityValid, setIsCityValid] = useState(false);
    const scrollRef = useRef();

    const registerUser = async () => {

        if (formOk) {
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
            const encryptedMail = encrypt.encrypt(email.toLowerCase().trim());
            const encryptedPssw = encrypt.encrypt(pssw.trim());

            const payload = {
                companyName: companyName.trim(),
                email: encryptedMail,
                pssw: encryptedPssw,
                distributionArea: distributionAreaISO.map((el) => { return el.split(":")[1] }),
                sellingAreaExcluded: sellingAreaExcludedISO.map((el) => { return el.split(":")[1] }),
                sellingAreaSelected: sellingAreaSelectedISO.map((el) => { return el.split(":")[1] }),
                country: country.split(":")[1],
                city: city,
                state: state,
                phone: `${phoneCode}-${phone.toString()}`,
                manufacturer: typeOfJob === "Manufacturer" ? 1 : 0,
                dealer: typeOfJob === "Dealer" ? 1 : 0,
                verifyCode: verifyCode,
                accountActive: 0
            };

            dispatch(postSigninFunc(payload))
                .then((res) => {
                    if (res.payload && res.payload.statusCode === 201) {

                        const encryptedEmail = encrypt.encrypt(email);
                        const encryptedCompanyName = encrypt.encrypt(companyName);
                        const encryptedVerifyCode = encrypt.encrypt(`${res.payload.data}-${verifyCode}`);

                        dispatch(postLoginFunc({ email: encryptedMail, pssw: encryptedPssw }))
                            .then((res) => {
                                serverResponseManagement(res, navigate, dispatch);
                                if (res.payload && res.payload.statusCode === 200) {

                                    dispatch(verifyMailFunc({ payload: { email: encryptedEmail, companyName: encryptedCompanyName, verifyCode: encryptedVerifyCode }, token: res.payload.token }))
                                        .then((res) => {
                                            serverResponseManagement(res, navigate, dispatch);
                                            if (res.payload && res.payload.statusCode === 400) {
                                                setServerResponse(res.payload.message);
                                                localStorage.setItem('verifyMailError', res.payload.message);
                                            }
                                        });

                                    localStorage.setItem('token', res.payload.token);
                                    setCompanyName("");
                                    setEmail("");
                                    setPssw("");
                                    setCountry("");
                                    setCity("");
                                    setPhone("");
                                    setTypeOfJob("");
                                    navigate("/account")
                                } else {
                                    setServerResponse(res.payload.message);
                                }
                            });


                    }
                    if (res.payload && res.payload.statusCode === 401) {
                        setServerResponse(res.payload.message);
                    }
                })
                .catch((err) => {
                    setServerResponse(err)
                })
        }

    };

    const getCitiesByCountry = (input) => {
        dispatch(getCitiesFunc({ country: input.split(":")[1], token: token }))
            .then((res) => {
                serverResponseManagement(res, navigate, dispatch);
            });
        dispatch(clearSearchCity());
        setIsCityValid(false);
        setCountry(input);
    };

    const manageStep = (input) => {
        if (input && step < 4) {
            setStep(step + 1)
        }
        if (!input && step > 0) {
            setStep(step - 1)
        }
    };

    useEffect(() => {
        country && phoneCodes.map((el) => { if (el.code === country.split(":")[1]) { setPhoneCode(el.dial_code) } });
    }, [country]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [step]);

    useEffect(() => {
        if (psswVisible) {
            setTimeout(() => {
                setPsswVisible(false)
            }, 5000)
        }
    }, [psswVisible])



    return (
        <div className='d-flex myBgDark111 align-items-start justify-content-center text-light myMinVh100' ref={scrollRef}>
            <div className='w-100 p-5 px-4 myMaxW800'>
                <h1 className='text-center fw-light'>Sign in</h1>

                {/* progress bar */}
                <div className='d-flex justify-content-center mt-3'>
                    <div className='myMaxW300 position-relative progressStepBar d-flex justify-content-center align-items-center'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className={`${step >= 0 ? "active" : ""}`}></div>
                            <div className={`${step >= 1 ? "active" : ""}`}></div>
                            <div className={`${step >= 2 ? "active" : ""}`}></div>
                            <div className={`${step >= 3 ? "active" : ""}`}></div>
                        </div>
                        <div className='position-absolute'></div>
                        <div className='position-absolute'
                            style={{
                                background: `${step === 0 ?
                                    "linear-gradient(to right, #f0bd35 15%, #666 10%, #666, #666)"
                                    : step === 1 ?
                                        "linear-gradient(to right, #f0bd35 50%, #666 10%, #666)"
                                        : step === 2 ?
                                            "linear-gradient(to right, #f0bd35 82%, #666 10%)"
                                            : "#f0bd35"
                                    }`
                            }}></div>
                    </div>
                </div>

                <hr className='mb-3' />
                <div>
                    {
                        step === 0 ?
                            <div>
                                <h4 className='fw-light  text-center'>Select your job:</h4>
                                <div className='d-flex flex-wrap align-items-center justify-content-center mb-5 mt-4 gap-4'>
                                    <div className={`bg-secondary position-relative text-light px-4 py-2 rounded-5 myCursor ${typeOfJob === "Dealer" ? "delManSelectorActive" : "delManSelector"}`} onClick={() => { setTypeOfJob("Dealer") }}>
                                        <i className="bi bi-check-circle-fill position-absolute"></i> Dealer
                                    </div>
                                    <div className={`bg-secondary position-relative text-light px-4 py-2 rounded-5 myCursor ${typeOfJob === "Manufacturer" ? "delManSelectorActive" : "delManSelector"}`} onClick={() => { setTypeOfJob("Manufacturer") }}>
                                        <i className="bi bi-check-circle-fill position-absolute"></i> Manufacturer
                                    </div>
                                </div>
                            </div>
                            : null
                    }

                    {
                        step === 1 ?
                            <div>
                                <div className="mb-3 d-flex gap-2 align-items-center">
                                    <InputGroup >
                                        <InputGroup.Text id="basic-addon1"><i className="bi bi-buildings-fill"></i></InputGroup.Text>
                                        <Form.Control
                                            placeholder="Company Name"
                                            aria-label="Company Name"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => { setCompanyName(e.target.value) }}
                                            value={companyName}
                                            maxLength={160}
                                        />
                                    </InputGroup>
                                    {companyName ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                                </div>

                                <div className="mb-3 d-flex gap-2 align-items-center">
                                    <InputGroup >
                                        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                        <Form.Control
                                            className='text-lowercase'
                                            placeholder="email"
                                            aria-label="email"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => { setEmail(e.target.value) }}
                                            value={email}
                                            maxLength={160}
                                        />
                                    </InputGroup>
                                    {isEmailValid ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                                </div>

                                <div>
                                    <div className="mb-3 d-flex gap-2 align-items-center">
                                        <InputGroup >
                                            <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                                            <Form.Control type={`${psswVisible ? "text" : "password"}`} placeholder="password" aria-label="Password" aria-describedby="basic-addon1"
                                                onChange={(e) => { setPssw(e.target.value); }}
                                                value={pssw}
                                                maxLength={26}
                                            />
                                            <div className=' rounded-end-5 d-flex justify-content-center align-items-center myBgDarkGray' onClick={() => setPsswVisible(!psswVisible)}>
                                                <i className={`bi bi-eye${psswVisible ? "" : "-slash"}-fill px-3 myCursor`}></i>
                                            </div>
                                        </InputGroup>
                                        {isPsswValid ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                                        {
                                            pssw.length > 2 && !isPsswValid ?
                                                <i className="bi bi-exclamation-diamond-fill text-warning ms-2"></i>
                                                : null
                                        }
                                    </div>
                                    {
                                        pssw.length > 2 && !isPsswValid ?
                                            <div className='bg-warning text-dark p-2 px-3'>
                                                <p className='m-0'>Password must be <b>longer than 7 characters</b>, shorter than 27 & must contain at least one of this special characters: <b>! # $ % & @ =</b></p>
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                            : null
                    }


                    {
                        step === 2 ?
                            typeOfJob === "Dealer" ?
                                <div> {/* Per i Dealer */}
                                    <h4 className=' fw-light'>Distribution Area</h4>
                                    <div className={`${openInfo ? "" : "line-clampBeta"} w-100 myLightGrayColor my-3 myCursor`} onClick={() => setOpenInfo(!openInfo)}><i className="bi bi-info-circle-fill myLightGrayColor"></i> This choice will be considered only as an account preference. During the product creation and editing stages, this preference can be changed for each individual published product, in order to allow you to make individual products visible (or invisible) in different countries.</div>
                                    <div className='d-flex justify-content-start mb-4'>
                                        {
                                            addArea ?
                                                <div>
                                                    <div className="mb-3 d-flex gap-2 align-items-center">
                                                        <InputGroup >
                                                            <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                            <Form.Control variant='dark' id="dropdown-basic-button"
                                                                placeholder='type a country'
                                                                onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    {
                                                        sellExclCountryDropdownOpen ?
                                                            <div className='position-relative'>
                                                                <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                    {
                                                                        countryFiltered && countryFiltered.map((el, index) => {
                                                                            return <div className='px-2 rounded-5 myCursor my-1' key={`signin1-${index}`} onClick={() => { dispatch(setDistributionArea(el)); setAddArea(false); setSellExclCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                : <div className={`${distributionAreaISO.length ? "disabledPlus" : ""} d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>add a country</div>
                                        }

                                    </div>
                                    <div className='text-light d-flex gap-2'>
                                        {
                                            distributionAreaISO && distributionAreaISO.map((el, index) => {
                                                return (
                                                    <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' key={`signin2-${index}`}>
                                                        {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delDistributionArea(el)) }}></i>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <div>{/* MANUFACTURERS */}
                                    <h5 className='mt-4 fw-bold text-center'><i className="bi bi-globe-americas"></i> Geographic visibility </h5>
                                    <div className={`${openInfo ? "" : "line-clampBeta"} w-100 myLightGrayColor my-3 myCursor`} onClick={() => setOpenInfo(!openInfo)}><i className="bi bi-info-circle-fill myLightGrayColor"></i> This choice will be considered only as an account preference. During the product creation and editing stages, this preference can be changed for each individual published product, in order to allow you to make individual products visible (or invisible) in different countries.</div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='d-flex text-center myBgDarkGray rounded-5 px-1 py-1 mt-2'>
                                            <h6 className={`${excludeCountry ? "myBgYellow text-dark" : ""} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(true) }}>Exclude country</h6>
                                            <h6 className={`${excludeCountry ? "" : "myBgYellow text-dark"} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(false) }}>Select country</h6>
                                        </div>
                                    </div>
                                    <div className='display-5 my-3 d-flex justify-content-center'>{excludeCountry ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}</div>
                                    <div className='d-flex justify-content-center'>
                                        {
                                            excludeCountry ?
                                                <p>The <b>Exclude country</b> allows you to make your products visible to Dealers located worldwide, <b>except for Dealers located in those countries you want to exclude from the list.</b></p>
                                                :
                                                <p>The <b>Select country</b> allows you to make your products visible to Dealers located <b>only in those countries you select from the list.</b></p>
                                        }
                                    </div>
                                    {
                                        excludeCountry ?
                                            <>
                                                <div className='d-flex justify-content-start'>
                                                    {
                                                        addArea ?
                                                            <div className="my-2 w-100">
                                                                <div className="mb-3 d-flex gap-2 align-items-center">
                                                                    <InputGroup >
                                                                        <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                        <Form.Control variant='dark' id="dropdown-basic-button"
                                                                            placeholder='type a country'
                                                                            onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                            onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                        />
                                                                    </InputGroup>
                                                                </div>
                                                                {
                                                                    sellExclCountryDropdownOpen ?
                                                                        <div className='position-relative'>
                                                                            <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                                {
                                                                                    countryFiltered && countryFiltered.map((el, index) => {
                                                                                        return <div className='px-2 rounded-5 myCursor my-1' key={`signin3-${index}`} onClick={() => { dispatch(setSellingAreaExcluded(el)); setAddArea(false); setSellExclCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            :
                                                            <div className='d-flex gap-4 align-items-center flex-wrap my-3 myCursor'>
                                                                <Form><Form.Check defaultChecked={sellWholeWorld ? "checked" : ""} type="switch" id="custom-switch" label="I want to sell worldwide" onClick={() => { setSellWholeWorld(!sellWholeWorld); dispatch(clearSellingAreaExcluded()); dispatch(clearSellingAreaSelected()) }} /></Form>
                                                                <div className={`${sellWholeWorld ? "disabledPlus" : null} d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>exclude a country</div>
                                                            </div>
                                                    }

                                                </div>
                                                {sellingAreaExcludedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Excluded country/ies</h4> : null}
                                                <div className='text-light d-flex flex-wrap gap-2'>
                                                    {
                                                        sellingAreaExcludedISO && sellingAreaExcludedISO.map((el, index) => {
                                                            return (
                                                                <div key={`signin4-${index}`} className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' >
                                                                    {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaExcluded(el)) }}></i>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='d-flex justify-content-start'>
                                                    {
                                                        addArea ?
                                                            <div className="my-2 w-100">
                                                                <div className="mb-3 d-flex gap-2 align-items-center">
                                                                    <InputGroup >
                                                                        <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                        <Form.Control variant='dark' id="dropdown-basic-button"
                                                                            placeholder='type a country'
                                                                            onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                            onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                        />
                                                                    </InputGroup>
                                                                </div>
                                                                {
                                                                    sellExclCountryDropdownOpen ?
                                                                        <div className='position-relative'>
                                                                            <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                                {
                                                                                    countryFiltered && countryFiltered.map((el, index) => {
                                                                                        return <div className='px-2 rounded-5 myCursor my-1' key={`signin5-${index}`} onClick={() => { dispatch(setSellingAreaSelected(el)); setAddArea(false); setSellExclCountryDropdownOpen(false); setSellWholeWorld(false) }}>{el.split(":")[0]}</div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            :
                                                            <div className={`d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>select a country</div>
                                                    }

                                                </div>
                                                {sellingAreaSelectedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Selected country/ies</h4> : null}
                                                <div className='text-light d-flex flex-wrap gap-2'>
                                                    {
                                                        sellingAreaSelectedISO && sellingAreaSelectedISO.map((el, index) => {
                                                            return (
                                                                <div key={`signin6-${index}`} className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry'>
                                                                    {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaSelected(el)) }}></i>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                    }
                                </div>
                            : null
                    }


                    {
                        step === 3 ?
                            <div>
                                <h4 className='  fw-light mb-4'>Company location</h4>

                                <div className="mb-3">
                                    <div className="d-flex gap-2 align-items-center">
                                        <InputGroup >
                                            <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                            <Form.Control variant='dark' id="dropdown-basic-button"
                                                placeholder='Country'
                                                value={country ? country.split(":")[0] : ""}
                                                onChange={(e) => { setCountry(e.target.value); dispatch(searchCountry(e.target.value)) }}
                                                onClick={() => { setCountryDropdownOpen(true); dispatch(clearSearchCity()) }}
                                                autoComplete="new-password"
                                            />
                                        </InputGroup>
                                        {country ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                                    </div>
                                    {
                                        countryDropdownOpen ?
                                            <div className='position-relative'>
                                                <div className='border w-100 p-3 customDropdown customDropdownMd position-absolute bg-light text-dark'>
                                                    {
                                                        countryFiltered && countryFiltered.map((el, index) => {
                                                            return <div className='px-2 rounded-5 myCursor my-1' key={`signin7-${index}`} onClick={() => { getCitiesByCountry(el); setCity(""); setCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                                <div className="mb-3">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <InputGroup >
                                            <InputGroup.Text id="basic-addon1"><i className="bi bi-geo-fill"></i></InputGroup.Text>
                                            <Form.Control
                                                disabled={country ? false : true}
                                                placeholder="City"
                                                aria-label="City"
                                                aria-describedby="basic-addon1"
                                                onChange={(e) => { dispatch(searchCity(e.target.value)); setCity(e.target.value); setIsCityValid(false) }}
                                                onClick={(e) => { dispatch(searchCity(e.target.value)) }}
                                                value={city}
                                                maxLength={250}
                                                autoComplete="new-password"
                                            />
                                        </InputGroup>
                                        {city ? isCityValid ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : <i class="bi bi-x-circle-fill myFucsiaRed"></i> : null}
                                    </div>
                                    {
                                        allCitiesFiltered.length !== 0 ?
                                            <div className='position-relative'>
                                                <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark myMaxH300'>
                                                    {
                                                        allCitiesFiltered && allCitiesFiltered.map((el, index) => {
                                                            return <div className='px-2 rounded-5 myCursor my-1' key={`signin8-${index}`} onClick={() => { setCity(el.name); dispatch(clearSearchCity()); setIsCityValid(true) }}>{el.name}</div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                                <div className="mb-3 d-flex gap-2 align-items-center">
                                    <InputGroup >
                                        <InputGroup.Text id="basic-addon1"><i className="bi bi-map-fill"></i></InputGroup.Text>
                                        <Form.Control
                                            disabled={country && city ? false : true}
                                            placeholder="State/Province"
                                            aria-label="State/Province"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => { setState(e.target.value) }}
                                            value={state}
                                            maxLength={45}
                                            autoComplete="new-password"
                                        />
                                    </InputGroup>
                                    {state ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                                </div>

                                <div className="mb-3 d-flex gap-2 align-items-center">
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1"><i className="bi bi-phone-fill"></i></InputGroup.Text>
                                        <DropdownButton disabled={country ? false : true} className="mb-3 w-100" variant='dark' id="dropdown-basic-button" title={phoneCode ? phoneCode : "select a prefix"}>
                                            {
                                                phoneCodes.map((el, index) => {
                                                    return <Dropdown.Item className='text-wrap' key={`signin9-${index}`} onClick={() => setPhoneCode(el.dial_code)}>{el.dial_code} ({el.name})</Dropdown.Item>
                                                })
                                            }
                                        </DropdownButton>
                                        <Form.Control
                                            disabled={country ? false : true}
                                            type='number'
                                            placeholder="Phone"
                                            aria-label="Phone"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setPhone(e.target.value)}
                                            value={phone}
                                            maxLength={45}
                                        />
                                    </InputGroup>
                                    {phone.length > 5 ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                                </div>

                            </div>
                            : null
                    }



                    <hr />
                    <div className='d-flex align-items-center justify-content-center'>
                        {
                            step === 0 ?
                                <Button className='rounded-5 px-4' variant="primary" disabled={typeOfJob ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>{signinLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
                                : step === 1 ?
                                    <div className='d-flex gap-4 align-items-center'>
                                        <h5 className='bi bi-arrow-return-left myCursor m-0 myLightGrayColor' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                                        <Button className='rounded-5 px-4' variant="primary" disabled={companyName && isEmailValid && isPsswValid ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>{signinLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
                                    </div>
                                    : step === 2 ?
                                        <div className='d-flex gap-4 align-items-center'>
                                            <h5 className='bi bi-arrow-return-left myCursor m-0 myLightGrayColor' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                                            <Button className='rounded-5 px-4' variant="primary" disabled={distributionAreaISO.length > 0 || (typeOfJob === "Manufacturer" && (sellWholeWorld || sellingAreaExcludedISO.length > 0 || sellingAreaSelectedISO.length > 0)) ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>{signinLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
                                        </div>
                                        : step === 3 ?
                                            <div className='d-flex gap-4 align-items-center'>
                                                <h5 className='bi bi-arrow-return-left myCursor m-0 myLightGrayColor' variant="secondary" onClick={() => { manageStep(false) }}> back</h5>
                                                <Button className='rounded-5 px-4' variant="primary" disabled={country && city && !countryDropdownOpen && typeOfJob && isEmailValid && isPsswValid && companyName && isCityValid ? false : true} onClick={() => { manageStep(true) }}><i className="bi bi-check2-square me-2"></i>{signinLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
                                            </div>
                                            :
                                            <div className='my-4'>
                                                <DisclaimerSignin />
                                                <div className='my-4'>
                                                    <InputGroup className="my-3 py-1 d-flex flex-column align-items-start gap-3 rounded-5">
                                                        <div className='d-flex justify-content-center align-items-center gap-2 align-items-center'>
                                                            <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={privacyPolicyAgree} onChange={() => { setPrivacyPolicyAgree(!privacyPolicyAgree) }} aria-label="Checkbox for following text input" />
                                                            <p className='m-0'>I declare that I have read and understood the <Link to={'/privacy'} className='myYellowColor' target='_blank'>Privacy Policy</Link></p>
                                                            {privacyPolicyAgree ? null : <i className="bi bi-dot myFucsiaRed display-6"></i>}
                                                        </div>
                                                        <div className='d-flex justify-content-center align-items-center gap-2 align-items-center'>
                                                            <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={privacyPolicyAgree} onChange={() => { setChildSafety(!childSafety) }} aria-label="Checkbox for following text input" />
                                                            <p className='m-0'>I declare that I have read and understood the <Link to={'/childsafety'} className='myYellowColor' target='_blank'>Child Safety Policy</Link></p>
                                                            {childSafety ? null : <i className="bi bi-dot myFucsiaRed display-6"></i>}
                                                        </div>
                                                        <div className='d-flex justify-content-center align-items-center gap-2 align-items-center'>
                                                            <InputGroup.Checkbox className='m-0 myCursor' defaultChecked={privacyPolicyAgree} onChange={() => { setImOver18(!imOver18) }} aria-label="Checkbox for following text input" />
                                                            <p className='m-0'><b>I confirm that I am of legal age. I understand terms of service.</b></p>
                                                            {imOver18 ? null : <i className="bi bi-dot myFucsiaRed display-6"></i>}
                                                        </div>
                                                    </InputGroup>
                                                </div>
                                                <div className='d-flex justify-content-center mt-5'>
                                                    <Button className='rounded-5 px-4' variant="primary"
                                                        disabled={country && city && !countryDropdownOpen && typeOfJob && isEmailValid && isPsswValid && companyName && isCityValid && imOver18 && privacyPolicyAgree && childSafety ? false : true}
                                                        onClick={() => { registerUser(); setFirstSendTry(false) }}
                                                    ><i className="bi bi-check2-square me-2"></i>{signinLoading ? <Spinner animation="border" size='sm' /> : "register"}</Button>
                                                </div>
                                            </div>
                        }
                    </div>
                    <p className="mt-3 text-center">{firstSendTry || formOk ? "" : <i className="bi bi-exclamation-triangle-fill text-danger"> Fill the form correctly</i>}</p>
                    {serverResponse ? <h6 className="mt-3 text-center fw-light text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></h6> : <p></p>}
                </div>

                <div className='pb-5'></div>
                <div className='pb-5'></div>
            </div>
        </div >
    )
}

export default _Signin