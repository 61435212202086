import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUserFunc } from '../states/loginState';
import { getAllAnnouncementsByIdOwnerFunc, getFavouritesAnnouncementsByIdFunc, setReloadFavouritesData } from '../states/storeState';
import { useNavigate } from 'react-router';
import { getUserOutletFunc } from '../states/outletStore';
import CardChatAnnouncement from './CardChatAnnouncement';
import ChatAnnouncement from './ChatAnnouncement';
import { getAllChatsNotifyByIdOwnerUserFunc, areThereNotify, goToMyChat, clearUsersById, setFavoritesAnnouncementsId } from '../states/chatState';
import decodeToken from '../functions/decodeToken';
import serverResponseManagement from '../functions/serverResponseManagement';
import { updateTutorialFunc } from '../states/tutorialState';
import TutorialChat from './_Tutorials/TutorialChat';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import LoadingBar from './LoadingBar';
import { IonSearchbar } from '@ionic/react';

const _Chat = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allUserAnnouncements = useSelector((state) => state.myStore.allData);
    const userData = useSelector((state) => state.login.userData);
    const [allUserAnnouncementsNotifyOrder, setAllUserAnnouncementsNotifyOrder] = useState(allUserAnnouncements);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const isLoadingTutorial = useSelector((state) => state.tutorial.isLoading);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const reloadFavouritesData = useSelector((state) => state.myStore.reloadFavouritesData);
    const [favouritesDataNotify, setFavouritesDataNotify] = useState(favouritesData);
    const [favouritesDataNotifyBkp, setFavouritesDataNotifyBkp] = useState(null);
    const favoritesAnnouncementsId = useSelector((state) => state.chat.favoritesAnnouncementsId);
    const myChatState = useSelector((state) => state.chat.myChatState);
    const notifyCountMyProducts = useSelector((state) => state.chat.notifyCountMyProducts);
    const notifyCountFavourites = useSelector((state) => state.chat.notifyCountFavourites);
    const outletUserData = useSelector((state) => state.outlet.outletUserData);
    const allNewLikesCount = useSelector((state) => state.myStore.allNewLikesCount);
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const allChatsUserNotifyId_notOwner = useSelector((state) => state.chat.allChatsUserNotifyId_notOwner);
    const disableBackArrowChat = useSelector((state) => state.chat.backArrowChatOff);
    const [isFavouritesChat, setIsFavouritesChat] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [openChat, setOpenChat] = useState(false);
    const [idChat, setIdChat] = useState(null);
    const [tutorialChat, setTutorialChat] = useState(false);
    const [tutorialChatStep, setTutorialChatStep] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const tutorialData = useSelector((state) => state.tutorial.tutorialData);
    const chatRef = useRef();

    const token = localStorage.getItem("token");
    const tkn = decodeToken(dispatch);

    const favourtitesChatOrganizer = () => {
        let notifyFavourites = [];
        let notNotifyFavourites = [];
        let notifyFavouritesId = [];
        let notifyFavouritesNewMssgCount = [];
        let notNotifyFavouritesId = [];
        if (favouritesData) {

            allChatsNotify && allChatsNotify.map((el) => {
                if (el.idUser === tkn.id) {
                    if (!el.userCheck) {
                        notifyFavouritesId.push(el.idAnn);
                        notifyFavouritesNewMssgCount.push({ idAnn: el.idAnn, newMssgCount: el.ownerLength - el.userLength })
                    } else {
                        notNotifyFavouritesId.push(el.idAnn)
                    }
                }
            })

            favouritesData && favouritesData.map((item) => {
                if (notifyFavouritesId.includes(item.id)) {
                    let myMssgCount = 0;
                    notifyFavouritesNewMssgCount.map((el) => { if (el.idAnn === item.id) { myMssgCount = el.newMssgCount } });
                    notifyFavourites.push({ ...item, notify: true, newMssgCount: myMssgCount })
                } else {
                    notNotifyFavourites.push({ ...item, notify: false })
                }

            });
            const myData = notifyFavourites.concat(notNotifyFavourites);

            if (!favoritesAnnouncementsId.length) {
                setFavouritesDataNotify(myData);
            } else {
                const finalFavouritesOrder = [];
                favoritesAnnouncementsId.map((el) => {
                    favouritesDataNotify.map((item) => {
                        if (item.id === +el) {
                            if (notifyFavouritesId.includes(item.id)) {
                                let myMssgCount = 0;
                                notifyFavouritesNewMssgCount.map((el) => { if (el.idAnn === item.id) { myMssgCount = el.newMssgCount } })
                                finalFavouritesOrder.push({ ...item, notify: true, newMssgCount: myMssgCount })
                            } else {

                                finalFavouritesOrder.push({ ...item, notify: false })
                            }
                        }
                    })
                });
                setFavouritesDataNotify(finalFavouritesOrder);
            }
            dispatch(areThereNotify({ chats: allChatsNotify, idOwner: tkn.id }));
        }
    };

    const announcementsChatOrganizer = () => {
        let notifyAnnouncement = [];
        let notNotifyAnnouncement = [];
        let likedAnnouncement = [];
        let notifyAnnouncementsNewMssgCount = [];
        let notifyAnnouncementId = [];
        let notNotifyAnnouncementId = [];
        if (allUserAnnouncements) {

            allChatsNotify && allChatsNotify.map((el) => {
                if (el.idOwner === tkn.id) {
                    if (!el.ownerCheck) {
                        notifyAnnouncementId.push(el.idAnn);
                        notifyAnnouncementsNewMssgCount.push({ idAnn: el.idAnn, newMssgCount: el.userLength - el.ownerLength })
                    } else {
                        notNotifyAnnouncementId.push(el.idAnn)
                    }
                }
            })

            allUserAnnouncements && allUserAnnouncements.map((item) => {
                if (notifyAnnouncementId.includes(item.id)) {
                    let myMssgCount = 0;
                    notifyAnnouncementsNewMssgCount.map((el) => { if (el.idAnn === item.id) { myMssgCount = el.newMssgCount } })
                    notifyAnnouncement.push({ ...item, notify: true, newMssgCount: myMssgCount })
                } else {
                    if (item.newLikes) {
                        likedAnnouncement.push(item)
                    } else {
                        notNotifyAnnouncement.push({ ...item, notify: false })
                    }
                }

            });
            setAllUserAnnouncementsNotifyOrder(notifyAnnouncement.concat(likedAnnouncement.concat(notNotifyAnnouncement)));
            dispatch(areThereNotify({ chats: allChatsNotify, idOwner: tkn.id }));
        }
    };

    const restoreScroll = (itemId) => {
        localStorage.setItem("restoreScrollId_chat", itemId)
    };

    const searchChat = (input) => {

        const filterdProducts = []
        favouritesDataNotifyBkp.map((el) => {
            if (el.brandName.toLowerCase().includes(input.toLowerCase()) || el.modelName.toLowerCase().includes(input.toLowerCase())) {
                filterdProducts.push(el)
            }
        });
        setFavouritesDataNotify(filterdProducts)
    };

    const closeSearchBarChat = (openSearchBar) => {
        if (openSearchBar) {
            setFavouritesDataNotify(favouritesDataNotifyBkp);
            setSearchValue("")
        } else {
            setFavouritesDataNotifyBkp(favouritesDataNotify)
        }
    };

    const closeChat = () => {
        setOpenChat(false);
        setIdChat(null);
        dispatch(clearUsersById());
        dispatch(goToMyChat(false))
    }

    useEffect(() => {
        if (token) {
            dispatch(getSingleUserFunc({ id: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                })
            dispatch(getAllChatsNotifyByIdOwnerUserFunc({ idOwnerUser: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200 && res.payload.data.length > 0) {
                        dispatch(areThereNotify({ chats: res.payload.data, idOwner: tkn.id }))
                    }
                })
            dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch)
                });

        } else {
            navigate('/login');
        }
    }, []);

    /* useEffect(() => {

        if (allChatsUserNotifyId_notOwner !== undefined) {//se ci sono annunci favourites con messaggi da leggere

            if (allChatsUserNotifyId_notOwner && allChatsUserNotifyId_notOwner.length) {
                dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                    .then((res) => {
                        serverResponseManagement(res, navigate, dispatch)
                        if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                            if (favouritesData && !favouritesData.length) {
                                dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token }))//allChatsUserNotifyId_notOwner.toString()
                                    .then((res) => {
                                        serverResponseManagement(res, navigate, dispatch)
                                    })
                            }
                        }
                    })
            } else {
                dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                    .then((res) => {
                        serverResponseManagement(res, navigate, dispatch)
                        if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                            if (favouritesData && !favouritesData.length) {
                                dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token }))
                                    .then((res) => {
                                        serverResponseManagement(res, navigate, dispatch)
                                    })
                            }
                        }
                    })
            }
        }
    }, [allChatsUserNotifyId_notOwner, reloadFavouritesData]); */

    useEffect(() => {
        if ((outletUserData && !outletUserData.length) || (favouritesData && !favouritesData.length) || reloadFavouritesData) {

            dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch)
                    if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {

                        dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token }))
                            .then((res) => {
                                dispatch(setFavoritesAnnouncementsId([]));
                                serverResponseManagement(res, navigate, dispatch)
                            })

                    }
                })

        }

    }, []);


    useEffect(() => {
        if (myChatState) {
            setIsFavouritesChat(myChatState.isFavouriteChat);
            setIdChat(myChatState.idChat);
            setOpenChat(myChatState.openChat)
        }
    }, [myChatState]);

    useEffect(() => {
        chatRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    useEffect(() => {
        if (token) {
            announcementsChatOrganizer()
        }
    }, [allChatsNotify, allUserAnnouncements]);

    useEffect(() => {
        if (token) {
            favourtitesChatOrganizer()
        }
    }, [allChatsNotify, favouritesData, favoritesAnnouncementsId]);

    useEffect(() => {
        if (favouritesDataNotify.length && !favoritesAnnouncementsId.length) {
            dispatch(setFavoritesAnnouncementsId(favouritesDataNotify.map((el) => { return el.id })));
        }
    }, [favouritesDataNotify]);

    //Tutorial Functions
    const updateTutorial = () => {
        dispatch(updateTutorialFunc({ payload: { chat: 0 }, token }))
            .then((res) => {
                if (res.payload.statusCode === 200) {
                    window.location.reload()
                }
            })
    };

    useEffect(() => {
        if (tutorialData && tutorialData.chat) {
            setTutorialChat(true)
        }
    }, [tutorialData]);

    useEffect(() => {
        if (!openChat) {
            const itemId = localStorage.getItem("restoreScrollId_chat")
            setTimeout(() => {
                document.getElementById(itemId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                localStorage.removeItem("restoreScrollId_chat")
            }, 100)
        }
    }, [openChat]);

    useEffect(() => {
        if (userData && userData.length > 0 && !userData[0].accountActive) {
            navigate('/account')
        }
    }, [userData])


    return (
        <div ref={chatRef}>
            <div className='position-relative'>
                {
                    tutorialChat ?
                        <div className='position-fixed h-100 start-0 d-flex flex-column align-items-center w-100 myZindex5 myOverflowY myMaxVh100 py-5'>
                            <div className='text-light border border-secondary shadow myBgTransparentVeryHigh m-3 mb-5 mt-4 rounded-5 p-3 text-center d-flex flex-column gap-5 myMaxW500'>
                                <TutorialChat step={tutorialChatStep} />
                                {
                                    tutorialChatStep === 0 ?
                                        <Button className='rounded-5 w-100' onClick={() => { setTutorialChatStep(1); setIsFavouritesChat(true); closeChat() }}>Next</Button>
                                        :
                                        tutorialChatStep === 1 ?
                                            <div className='d-flex gap-2'>
                                                <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialChatStep(0) }}>Back</Button>
                                                <Button className='rounded-5 w-75' onClick={() => { setTutorialChatStep(2); setIsFavouritesChat(false) }}>Next</Button>
                                            </div>
                                            :
                                            <div className='d-flex gap-2'>
                                                <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialChatStep(1); setIsFavouritesChat(true) }}>Back</Button>
                                                <Button className='rounded-5 w-75' disabled={isLoadingTutorial ? true : false} onClick={updateTutorial}>Next {isLoadingTutorial ? <Spinner variant='light' animation='border' size='sm' /> : null}</Button>
                                            </div>
                                }
                            </div>
                        </div>
                        : null
                }
            </div>

            {
                openChat ?
                    null
                    :
                    <div className='d-flex justify-content-center align-items-center w-100'>
                        <div className='d-flex align-items-center mt-2 me-4'>
                            <i className={`${openSearchBar ? "bi bi-x-lg" : "bi bi-search"} display-6 text-light myCursor`} onClick={() => { closeSearchBarChat(openSearchBar); setOpenSearchBar(!openSearchBar); }}></i>
                        </div>
                        <div className='d-flex justify-content-center gap-2 mb-3 pt-4'>
                            <span className={`position-relative ${isFavouritesChat ? "myBgFucsiaRed" : "bg-secondary"} fw-bold text-light myCursor p-1 px-3 rounded-5`} onClick={() => setIsFavouritesChat(true)}><i className="bi bi-heart-fill me-1"></i>
                                Favourites
                                <div className='notifyGeneralChat position-absolute d-flex'>
                                    {notifyCountFavourites ? <div className='notifyDotChat myFontSizeNotifyCount d-flex align-items-center justify-content-center'>{notifyCountFavourites > 99 ? 99 : notifyCountFavourites}</div> : null}
                                </div>
                            </span>
                            <span className={`position-relative ${!isFavouritesChat ? "myBgYellow text-dark" : "bg-secondary text-light"} fw-bold myCursor p-1 px-3 rounded-5`} onClick={() => setIsFavouritesChat(false)}><i className="bi bi-house-fill me-1"></i>
                                My Products
                                <div className='notifyGeneralChat position-absolute d-flex'>
                                    {notifyCountMyProducts ? <div className='notifyDotChat myFontSizeNotifyCount d-flex align-items-center justify-content-center'>{notifyCountMyProducts > 99 ? 99 : notifyCountMyProducts}</div> : null}
                                    {allNewLikesCount ? <div className='notifyDotLikes myFontSizeNotifyCount d-flex align-items-center justify-content-center'>{allNewLikesCount > 99 ? 99 : allNewLikesCount}</div> : null}
                                </div>
                            </span>
                        </div>
                        <div className='d-flex align-items-center mt-2 ms-4'>
                            <i className="bi bi-arrow-clockwise display-6 myCursor text-light" onClick={() => { window.location.reload() }}></i>
                        </div>
                    </div>
            }

            {
                openChat ?//per evitare che la barra di loading si veda anche nella chat aperta, risultando un una doppia loading bar
                    null
                    :
                    isLoading ?
                        <LoadingBar />
                        :
                        isFavouritesChat ?
                            !favouritesData.length ? <h1 className='mt-5 fw-light text-center text-secondary'>Chat is empty</h1> : null
                            : !allUserAnnouncementsNotifyOrder.length ? <h1 className='mt-5 fw-light text-center text-secondary'>Chat is empty</h1> : null
            }


            {
                width < 1100 ? //CHAT MOBILE VERSION
                    <div className='d-flex text-light'>
                        {
                            isFavouritesChat ?
                                <div className='d-flex align-items-center flex-column w-100 ' >
                                    {
                                        openSearchBar && !openChat ?
                                            <div className='d-flex justify-content-center align-items-center px-3 mb-2 w-100'>
                                                <IonSearchbar className=" myMaxW700 p-0 rounded-5" animated={true} placeholder="Search a category" color="medium" showClearButton="focus"
                                                    value={searchValue}
                                                    onIonInput={(e) => { setSearchValue(e.detail.value); searchChat(e.detail.value) }}
                                                    debounce={600}
                                                ></IonSearchbar>
                                            </div>
                                            : null
                                    }
                                    {
                                        favouritesDataNotify && favouritesDataNotify.map((el, index) => {//FAVOURITE CHAT
                                            if (openChat && el.id === idChat) {
                                                return <div key={`account3.1-${index}`} className=' position-relative bg-light d-flex justify-content-center' >
                                                    <i className="bi bi-chevron-left position-fixed start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { closeChat() }}></i>
                                                    <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                </div>
                                            } if (!openChat) {
                                                return <div key={`account3.2-${index}`} id={`${el.id}`} className='w-100 d-flex justify-content-center' onClick={() => { restoreScroll(el.id); setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })) }}>
                                                    <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                                :
                                <div className='d-flex align-items-center flex-column w-100'>
                                    {
                                        allUserAnnouncementsNotifyOrder && allUserAnnouncementsNotifyOrder.map((el, index) => {//ANNOUNCEMENT CHAT
                                            if (openChat && el.id === idChat) {
                                                return <div key={`account4.1-${index}`} className='position-relative bg-light d-flex justify-content-center' >
                                                    {!disableBackArrowChat && <i className="bi bi-chevron-left position-fixed start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { closeChat() }}></i>}
                                                    <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                </div>
                                            } if (!openChat) {
                                                return <div key={`account4.2-${index}`} className='w-100 d-flex justify-content-center' onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: false, openChat: true })) }}>
                                                    <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>

                        }

                    </div>
                    : null
            }


            {
                width >= 1100 ? //CHAT DESKTOP VERSION
                    <div className='text-light'>
                        {
                            isFavouritesChat ?
                                <>
                                    <div className='d-flex myVhFull'>
                                        <div className='d-flex align-items-center flex-column myOverflowY pb-5' style={{ minWidth: "400px", maxWidth: "700px" }}>
                                            {
                                                openSearchBar && !openChat ?
                                                    <div className='d-flex justify-content-center align-items-center px-3 mb-3 w-100'>
                                                        <IonSearchbar className="myMaxW500 p-0 rounded-5" animated={true} placeholder="Search a category" color="medium" showClearButton="focus"
                                                            value={searchValue}
                                                            onIonInput={(e) => { setSearchValue("e.detail.value") }}
                                                            debounce={600}
                                                        ></IonSearchbar>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                favouritesDataNotify && favouritesDataNotify.map((el, index) => {//FAVOURITE CHAT

                                                    return <div key={`account5-${index}`} className={`w-100 pt-1 d-flex justify-content-center ${idChat === el.id ? "myBgChatSelected" : ""}`} onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })) }}>
                                                        <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                    </div>

                                                })
                                            }
                                        </div>
                                        <div className='w-50' style={{ minWidth: "700px" }}>
                                            {
                                                favouritesDataNotify && favouritesDataNotify.map((el, index) => {
                                                    if (openChat && el.id === idChat) {
                                                        return <div key={`account6-${index}`} className=' position-relative bg-light d-flex justify-content-start' >
                                                            <i className="bi bi-chevron-left position-fixed ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { closeChat() }}></i>
                                                            <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='d-flex myVhFull'>
                                        <div className='d-flex align-items-center flex-column myOverflowY pb-5' style={{ minWidth: "400px", maxWidth: "700px" }}>
                                            {
                                                allUserAnnouncementsNotifyOrder && allUserAnnouncementsNotifyOrder.map((el, index) => {//ANNOUNCEMENT CHAT

                                                    return <div key={`account7-${index}`} className={`w-100 d-flex justify-content-center ${idChat === el.id ? "myBgChatSelected" : ""}`} onClick={() => { setOpenChat(true); setIdChat(el.id); dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: el.id, typeSubMenu: 2, isFavouriteChat: false, openChat: true })) }}>
                                                        <CardChatAnnouncement outletUserData={outletUserData} idOwn={tkn.id} singleData={el} isLoading={isLoading} notify={el.notify} newMssgCount={el.newMssgCount} />
                                                    </div>

                                                })
                                            }
                                        </div>
                                        <div className='w-100' style={{ minWidth: "700px" }}>
                                            {
                                                allUserAnnouncements && allUserAnnouncements.map((el, index) => {
                                                    if (openChat && el.id === idChat) {
                                                        return <div key={`account8-${index}`} className='position-relative bg-light d-flex justify-content-start' >
                                                            {!disableBackArrowChat && <i className="bi bi-chevron-left position-fixed ms-2 mt-4 pt-2 mx-1 myCursor display-6 myZindex5" onClick={() => { closeChat() }}></i>}
                                                            <ChatAnnouncement outletUserData={outletUserData} singleData={el} isLoading={isLoading} idOwn={tkn.id} width={width} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                    : null
            }

        </div>
    )
}

export default _Chat