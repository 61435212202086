import React, { useEffect, useState } from 'react';
import _Sidebar from '../components/_Sidebar';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { IonContent } from '@ionic/react';
import DisclaimerMain from '../components/_Disclaimers/DisclaimerMain';
import { App as CapacitorApp } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';


const MainLayout = ({ children }) => {

    const loading1 = useSelector((state) => state.signin.loading);
    const userData = useSelector((state) => state.login.userData);
    const geonamesLoading = useSelector((state) => state.geonames.isLoading);
    const token = localStorage.getItem("token");
    const mainRoutes = ['/store'];

    useEffect(() => {
        SplashScreen.hide()
    }, [])

    useEffect(() => {
        CapacitorApp.addListener("backButton", ({ canGoBack }) => {
            if (!canGoBack) {
                CapacitorApp.exitApp(); // Exit the app
            } else {
                window.history.back(); // Navigate back
            }
        });

        return () => {
            CapacitorApp.removeAllListeners("backButton"); // Clean up listener
        };
    }, []);


    return (
        <>
            <IonContent>
                <div style={{ height: "100%" }}>
                    {
                        mainRoutes.includes(window.location.pathname) && token && userData[0] && userData[0].legalAgreement === 0 ? <DisclaimerMain /> : null
                    }
                    {
                        loading1 || geonamesLoading ?
                            <div className='w-100 d-flex align-items-center justify-content-center' style={{ position: "fixed", height: "100%", zIndex: "103", backgroundColor: "rgb(255,255,255,.7)" }}>
                                <Spinner animation="border" role="status" variant='warning'></Spinner>
                            </div>
                            : null
                    }

                    <main style={{ minHeight: "calc(100vh - 65px)", paddingTop: "env(safe-area-inset-top)" }}>
                        {children}
                    </main>

                    <div style={{ height: "65px" }}>{/* sidebar spacer */}</div>
                    <_Sidebar />
                </div>
            </IonContent>
        </>
    )
}

export default MainLayout