import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { postLoginFunc } from '../states/loginState';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { userSearchFunc } from '../states/loginState';
import Logo from '../assets/Graphics/mainLogo.svg';
import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import serverResponseManagement from '../functions/serverResponseManagement';
import { NativeBiometric, BiometryType } from "@capgo/capacitor-native-biometric";
import { Capacitor } from '@capacitor/core';

const _Login = () => {
    const [email, setEmail] = useState('');
    const [pssw, setPssw] = useState('');
    const [firstSendTry, setFirstSendTry] = useState(true);
    const [serverResponse, setServerResponse] = useState("");
    const [resetPssw, setResetPssw] = useState(false);
    const [emailCheckHolding, setCheckEmailHolding] = useState(false);
    const [psswVisible, setPsswVisible] = useState(false);
    const [jailLoading, setJailLoading] = useState(false);
    const [isNativePlatform, setIsNativePlatform] = useState(undefined);
    const [chooseBiometrics, setChooseBiometrics] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginLoading = useSelector((state) => state.login.loading)

    const isEmailValid = email.includes("@") && email.includes(".") && email.length > 6;
    const formOk = isEmailValid && pssw;

    const loginFunc = async ({ email, pssw }) => {

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
        const encryptedMail = encrypt.encrypt(email.toLowerCase().trim());
        const encryptedPssw = encrypt.encrypt(pssw.trim());

        let loginPayload = {
            email: encryptedMail,
            pssw: encryptedPssw
        }


        if (email && pssw) {

            dispatch(postLoginFunc(loginPayload))
                .then((res) => {
                    if (res.payload && res.payload.statusCode === 500) {
                        navigate('/servererror')
                    };
                    if (res.payload && res.payload.statusCode === 429) {
                        setJailLoading(true);
                        setTimeout(() => {
                            setJailLoading(false)
                            serverResponseManagement(res, navigate, dispatch);
                        }, 15000)
                    };
                    if (res.payload && res.payload.statusCode === 200) {
                        localStorage.setItem('token', res.payload.token);
                        setEmail('');
                        setPssw('');
                        navigate('/')
                    } else {
                        setServerResponse(res.payload.message)
                        if (res.payload && res.payload.statusCode === 403) {
                            setTimeout(() => {
                                navigate('/banned')
                            }, 2000)
                        };
                    }
                })
                .catch((err) => {
                    console.log('login or server error', err);
                })
        }

    }

    const handleSubmit = async () => {
        if (isNativePlatform) {
            setChooseBiometrics(true)
        } else {
            loginFunc({ email: email, pssw: pssw })
        }
    };

    const manageReset = async () => {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
        const encryptedMail = encrypt.encrypt(email.toLowerCase().trim());

        dispatch(userSearchFunc({ email: encryptedMail }))
            .then((res) => {
                if (res.payload && res.payload.statusCode === 500) {
                    navigate('/servererror')
                };
                if (res.payload && res.payload.statusCode === 200) {
                    setCheckEmailHolding(true)
                } else {
                    setServerResponse(res.payload.message);
                }
            })
    };

    useEffect(() => {
        if (psswVisible) {
            setTimeout(() => {
                setPsswVisible(false)
            }, 5000)
        }
    }, [psswVisible]);



    const performBiometricVerification = async () => {
        const result = await NativeBiometric.isAvailable();

        if (!result.isAvailable) return;

        const isFaceID = result.biometryType == BiometryType.FACE_ID;
        const isTouchId = result.biometryType == BiometryType.TOUCH_ID;


        const verified = await NativeBiometric.verifyIdentity({
            reason: "For easy log in",
            title: "Biometrics",
            subtitle: "Auto-login with biometrics.",
            description: "",
        })
            .then(() => true)
            .catch(() => false);

        console.log("verified: ", verified);

        if (verified) {
            const credentials = await NativeBiometric.getCredentials({
                server: "...",
            });

            setEmail(credentials.username);
            setPssw(credentials.password);
            loginFunc({ email: credentials.username, pssw: credentials.password })
        };

    };

    const setMyCredentials = async () => {
        if (Capacitor.isNativePlatform()) {

            NativeBiometric.setCredentials({
                username: email,
                password: pssw,
                server: "...",
            })

        }
    };

    const deleteMyCredentials = async () => {
        if (Capacitor.isNativePlatform()) {

            await NativeBiometric.deleteCredentials({
                server: "...",
            })
        }
    };

    const checkForCredentials = async () => {
        const credentials = await NativeBiometric.getCredentials({
            server: "...",
        });
        if (credentials.username && credentials.password) {
            performBiometricVerification()
        }
    }


    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            checkForCredentials()
        }
    }, []);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            setIsNativePlatform(true)
        } else {
            setIsNativePlatform(false)
        }
    }, [])

    return (
        <div className='d-flex align-items-center justify-content-center position-relative' style={{ height: "calc(100vh - 65px)" }}>
            {
                chooseBiometrics && isNativePlatform ?
                    <div className='d-flex justify-content-center align-items-center position-absolute top-0 h-100 myBgTransparentVeryHigh'>
                        <div className='d-flex flex-column gap-5 shadow border border-dark text-center text-light myZindex2 p-4 m-2 rounded-5 myBgDark222'>
                            <h6>Do you want to enable auto-login with biometrics?</h6>
                            <div className='display-6 myYellowColor d-flex justify-content-center gap-3'>
                                <i className="bi bi-fingerprint "></i>
                                <i class="bi bi-person-bounding-box"></i>
                            </div>
                            <div className='d-flex justify-content-center gap-4'>
                                <Button className=' px-3 py-1 rounded-5' variant='warning'
                                    onClick={() => { deleteMyCredentials(); setMyCredentials(); setChooseBiometrics(false); loginFunc({ email: email, pssw: pssw }) }}
                                >enable</Button>
                                <Button className=' px-3 py-1 rounded-5' variant='secondary' onClick={() => { setChooseBiometrics(false); loginFunc({ email: email, pssw: pssw }) }}>manual login</Button>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <div className='text-light w-100 myMaxW600 p-3 pt-0 mx-1'>


                {
                    resetPssw || emailCheckHolding ?
                        null :
                        <div>
                            <div className='mb-4 logoLogin display-4 text-center montserrat-alternates-medium'>myStocker<img className='m-1 ms-3' src={Logo} /></div>

                            <h3 className='text-center myMidGrayColor mb-4'>login</h3>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control placeholder="email" aria-label="email" aria-describedby="basic-addon1"
                                    className='text-lowercase'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    maxLength={160}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                                <Form.Control type={`${psswVisible ? "text" : "password"}`} placeholder="password" aria-label="Password" aria-describedby="basic-addon1"
                                    onChange={(e) => { setPssw(e.target.value); }}
                                    value={pssw}
                                    maxLength={26}
                                />
                                <div className=' rounded-end-5 d-flex justify-content-center align-items-center myBgDarkGray' onClick={() => setPsswVisible(!psswVisible)}>
                                    <i className={`bi bi-eye${psswVisible ? "" : "-slash"}-fill px-3 myCursor`}></i>
                                </div>
                            </InputGroup>

                            {
                                isNativePlatform ?
                                    <div className='d-flex justify-content-center myYellowColor'>
                                        <div className='d-flex justify-content-center align-items-center myYellowColor gap-2 border border-warning rounded-5 px-3 '
                                            onClick={() => checkForCredentials()}>
                                            <i className="bi bi-fingerprint"></i>
                                            <i className="bi bi-person-bounding-box"></i>
                                            use biometrics
                                        </div>
                                    </div>
                                    : null
                            }

                            <div className='d-flex align-items-center justify-content-center pt-3'>
                                <Button className='w-100 fw-bold' variant='warning' disabled={formOk && !jailLoading ? false : true} onClick={() => { handleSubmit(); setFirstSendTry(false) }}>{loginLoading || jailLoading ? <Spinner animation="border" size='sm' /> : "login"}</Button>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='mt-4'>
                                    <Link className='link-warning' to={'/signin'}><h6 className='myCursor fw-bold m-0' onClick={() => setResetPssw(true)}>Create Account</h6></Link>
                                </div>
                                <div className='mt-4'>
                                    <a className='myCursor myLightGrayColor' onClick={() => setResetPssw(true)}>forgot password?</a>
                                </div>
                            </div>
                            <p className="mt-3 text-center">{firstSendTry || formOk ? "" : <i className="bi bi-exclamation-triangle-fill text-danger"> Fill the form correctly</i>}</p>
                            {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
                        </div>
                }

                <p>
                    Do you want to know more about our <Link to={'/privacy'} target='_blank' className='myYellowColor'>Privacy Policy</Link>?
                </p>
                <p>
                    Do you want to know more about our <Link to={'/childsafety'} target='_blank' className='myYellowColor'>Child Safety Policy</Link>?
                </p>

                {
                    resetPssw && !emailCheckHolding ?
                        <div>
                            <h3 className='text-center myMidGrayColor mb-4'>Forgot Password?</h3>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control placeholder="email" aria-label="email" aria-describedby="basic-addon1"
                                    className='text-lowercase'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    maxLength={160}
                                />
                            </InputGroup>
                            <div className='d-flex align-items-center justify-content-center pt-2'>
                                <Button className='w-100 fw-bold' variant="warning" disabled={isEmailValid ? false : true} onClick={() => { manageReset() }}>{loginLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
                            </div>
                            <div className='mt-4 text-end'>
                                <a className='myCursor myLightGrayColor' onClick={() => setResetPssw(false)}>login</a>
                            </div>
                            {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
                        </div>
                        : null
                }

                {
                    emailCheckHolding ?
                        <div className='pt-2'>
                            <div className='d-flex align-items-center justify-content-center gap-3'>
                                <Spinner animation="grow" size='sm' />
                                <span>Check your email. We sent a link to set a new password.</span>
                            </div>
                            <p className='mt-3 text-center myLightGrayColor'>* If you haven't received any email, repeat the process from the beginning, making sure to insert correctly the same email with which the account has been created.</p>
                            <div className='text-center'><a className='myCursor link-warning' onClick={() => window.location.reload()}>login</a></div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default _Login