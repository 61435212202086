
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateScore } from '../states/annScoreState';
import Placeholder from 'react-bootstrap/Placeholder';
import tapRight from '../assets/Graphics/swipe-right.png';
import tapLeft from '../assets/Graphics/swipe-left.png'
import pricePerItem from '../functions/pricePerItem';
import ScoreBar from './ScoreBar';
import { Button, InputGroup } from 'react-bootstrap';
import { reportUserFunc } from '../states/reportedUserState';
import decodeToken from '../functions/decodeToken';
import serverResponseManagement from '../functions/serverResponseManagement';
import { useNavigate } from 'react-router';


const CardAnnouncement = ({ singleData, isLoading }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [picsNum, setPicsNum] = useState(0);
    const [score, setScore] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [tapImgRightTutorial, setTapImgRightTutorial] = useState(true);
    const [tapImgLeftTutorial, setTapImgLeftTutorial] = useState(false);
    const outletUserData = useSelector((state) => state.outlet.outletUserData);
    const [tutorial, setTutorial] = useState(true);
    const [tutorialCounter, setTutorialCounter] = useState(0);



    const changePic = (input) => {

        if (input > (width / 2) && picsNum < singleData[0].pics.split(",").length - 1) {
            setPicsNum(picsNum + 1);
            if (!tutorialCounter) { setTutorialCounter(1) }
        }
        if (input < (width / 2) && picsNum > 0) {
            setPicsNum(picsNum - 1);
            if (tutorialCounter === 1) { setTutorialCounter(2) }
        }
    };

    //announcement score
    const finalScore = useSelector((state) => state.annScore.finalScore);

    useEffect(() => {
        singleData[0] && dispatch(generateScore(singleData[0]));
    }, [singleData[0]])

    useEffect(() => {
        if (singleData[0]) {
            finalScore && finalScore.map((el) => { if (singleData[0].id === el.id) { setScore(el.score) } })
        }
    }, [finalScore])

    useEffect(() => {
        setTutorial(true);
        if (outletUserData.length && !outletUserData[0].outletHistory) {//per evitare che questo tutorial si sovrapponga a quello dei like
            setTutorial(false);
        }
        if (outletUserData.length && outletUserData[0].outletHistory && outletUserData[0].outletHistory.split(",").length > 5 || tutorialCounter === 2) {
            setTutorial(false);
        }
    }, [outletUserData])

    return (


        <div className='rounded-0 myBgAbsBlack text-light w-100 myMaxW800 position-relative'>

            <div className='p-3 pb-0'>
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={12} style={{ height: '400px' }} /><div className='myWidthMaxW'></div></Placeholder>
                        :
                        <div>
                            <div className='rounded-4 position-relative'>
                                <div className='mb-2 rounded-4 myBgImgContain myWidthMaxW myCursor' style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData[0].pics.split(",")[picsNum]}")`, height: "400px", zIndex: "99" }} onClick={(e) => { changePic(e.clientX) }}></div>
                                {
                                    singleData[0].pics.split(",").length > 1 &&
                                    <div className='d-flex justify-content-between position-absolute h-100 w-100 top-0' onClick={(e) => { changePic(e.clientX) }}>
                                        {
                                            tutorial && tapImgLeftTutorial && !tapImgRightTutorial ?
                                                <div className='myBgTransparentMedium d-flex align-items-center justify-content-center px-4 rounded-start-4 myCursor w-50' onClick={() => { setTapImgLeftTutorial(false) }}>
                                                    <img className='h-25 tapAnimation' src={tapLeft} alt="" />
                                                </div>
                                                : <div className='d-flex align-items-center px-4 rounded-start-4 w-50'></div>
                                        }
                                        {
                                            tutorial && !tapImgLeftTutorial && tapImgRightTutorial ?
                                                <div className=' myBgTransparentMedium d-flex align-items-center justify-content-center px-4 rounded-end-4 myCursor w-50' onClick={() => { setTapImgRightTutorial(false); setTapImgLeftTutorial(true) }}>
                                                    <img className='h-25 tapAnimation' src={tapRight} alt="" />
                                                </div>
                                                : <div className='d-flex align-items-center px-4 rounded-end-4 w-50'></div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                {
                                    singleData[0].pics.split(",").map((el, index) => {
                                        return <div key={`cardannouncement1-${index}`} className={`${index === picsNum ? "myBgYellow imgDotsCounterActive" : "myBgDarkGray imgDotsCounter"} rounded-5 myCursor`} style={{ marginLeft: "2px" }} onClick={() => setPicsNum(index)}></div>
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                        <div className='my-2 d-flex flex-wrap justify-content-start gap-3 align-items-center'>
                            <h6 className='m-0'><i className="bi bi-eye-fill"></i> {singleData[0].views}</h6>
                            <h6 className='m-0 myFucsiaRed'><i className="bi bi-suit-heart-fill" ></i> {singleData[0].posClick}</h6>
                        </div>
                }
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={10} /></Placeholder> : <h3 className='fw-light'>{singleData[0].modelName}</h3>
                }

                {
                    !singleData[0] || isLoading ?
                        null :
                        <div className='d-flex justify-content-center pt-2'>
                            <ScoreBar score={score} type={1} />
                            {/* <div className='w-100 d-flex align-items-center mt-2 px-2 myMaxW600'>
                                <div className=' bg-secondary m-0 border border-secondary w-100 rounded-5' style={{ height: "6px" }}>
                                    <div className={`scoreBarLow rounded-5 h-100 ${score > 91 ? "scoreBarLegend" : score > 79 ? "scoreBarHigh" : score > 59 ? "scoreBarMedium" : null}`} style={{ width: `${score}%` }}></div>
                                </div>
                                <h3 className='ms-2 d-flex gap-2 align-items-center noBreak'> <p className='m-0 noBreak'>score:</p> {score / 10}</h3>
                            </div> */}
                        </div>
                }
            </div>

            <div className='text-dark rounded-5 p-3 my-3 mx-2 myBgYellow' >
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={6} /></Placeholder> :
                        <div className='d-flex align-items-center flex-wrap gap-2 justify-content-between'>
                            <div className='d-flex align-items-top'>
                                <h2 className={`${singleData[0].price > 0 ? "" : "myBlurryPrice"} fw-normal`}>{singleData[0].price > 0 ? singleData[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "000"}</h2>
                                <p className='fw-light'>,00{singleData[0].currency ? singleData[0].currency.split(":")[1] : null}</p>
                            </div>
                            <div className='d-flex align-items-center px-3 py-1 rounded-5 myBgDark text-light'>
                                <h2 className={`${singleData[0].price > 0 ? "" : "myBlurryPrice"} fw-normal`}>{pricePerItem(singleData[0].price, singleData[0].quantity).number}</h2>
                                <p className='fw-light'>{pricePerItem(singleData[0].price, singleData[0].quantity).decimal}</p>
                                <h4 className='fw-light'>{singleData[0].currency ? singleData[0].currency.split(":")[1] : null}/item</h4>
                            </div>
                        </div>
                }
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={6} /></Placeholder> :
                        <div className='d-flex justify-content-between gap-3 mt-2 me-2 ms-1'>
                            <span>Quantity: <b>{singleData[0].quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</b></span>
                            <span>Brand: <b>{singleData[0].brandName}</b></span>
                        </div>
                }
            </div>

            <div className='p-3'>
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                        <div>
                            <h5 className='mb-1 fw-normal'>Description</h5>
                            <h5 className='fw-light mt-3'>{singleData[0].description}</h5>
                        </div>
                }
                {
                    !singleData[0] || isLoading ?
                        <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                        <div className='my-5'>
                            <hr />
                            <h5 className='fw-normal'> Technical details</h5>
                            <div>
                                <ul className='mt-3'>
                                    {
                                        singleData[0].techDetail && singleData[0].techDetail.split(",").map((el, index) => {
                                            return <li key={`cardannouncement2-${index}`}>{el.split("£")[1]}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                }

            </div>

        </div >

    )
}

export default CardAnnouncement