import React, { useEffect, useState, useRef } from 'react';
import CardAnnouncement from './CardAnnouncement';
import { useDispatch, useSelector } from 'react-redux';
import decodeToken from '../functions/decodeToken';
import { getAnnouncementsByInterestsFunc, getAnnouncementsByIdFunc, updateAnnouncementFunc, setOutletData, setReloadFavouritesData } from '../states/storeState';
import { getUserOutletFunc, updateUserOutlet, updateOutletUserData } from '../states/outletStore';
import { useNavigate } from 'react-router';
import { getSingleUserFunc } from '../states/loginState';
import Lottie from 'lottie-react';
import idontcareAnim from '../assets/Animation/iDontCare.json';
import ilikeitAnim from '../assets/Animation/iLikeIt.json';
import BoxFalling from '../assets/Animation/boxFalling.json';
import SwipeRight from '../assets/Animation/swipeRight.json';
import serverResponseManagement from '../functions/serverResponseManagement';
import OutletTimerExplanation from './OutletTimerExplanation';
import { Button, InputGroup, Spinner } from 'react-bootstrap';
import TutorialMarket from './_Tutorials/TutorialMarket';
import { updateTutorialFunc } from '../states/tutorialState';
import { reportUserFunc } from '../states/reportedUserState';


const _Store = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const outletData = useSelector((state) => state.myStore.outletData);
  const outletUserData = useSelector((state) => state.outlet.outletUserData);
  const waitingForAngelina = useSelector((state) => state.myStore.waitingForAngelina);
  const isLoadingGetAnnouncements = useSelector((state) => state.myStore.isLoadingGetAnnouncements);
  const isLoadingTutorial = useSelector((state) => state.tutorial.isLoading);
  const isLoadingSendReport = useSelector((state) => state.reportedUser.isLoading);
  const isLoadingStore = useSelector((state) => state.myStore.isLoading);
  const isLoadingUser = useSelector((state) => state.login.loadingUser);
  const isLoadingOutlet = useSelector((state) => state.outlet.isLoading);
  const isLoadingChat = useSelector((state) => state.chat.loading);
  const tkn = decodeToken(dispatch);
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState("");
  const [waitForTimeReset, setWaitForTimeReset] = useState(false);
  const [remainingPercentage, setRemainingPercentage] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [swipeXStartPosition, setSwipeXStartPosition] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [triggerAnimLike, setTriggerAnimLike] = useState(false);
  const [triggerAnimDontCare, setTriggerAnimDontCare] = useState(false);
  const [tutorial, setTutorial] = useState(false);
  const [tutorialLike, setTutorialLike] = useState(true);
  const [tutorialMarket, setTutorialMarket] = useState(false);
  const [tutorialMarketStep, setTutorialMarketStep] = useState(0);
  const [payloadOutletGlobal, setPayloadOutletGlobal] = useState({});
  const [outletEnergyCount, setOutletEnergyCount] = useState(10);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportSuccessfullyReported, setReportSuccessfullyReported] = useState(false);
  const reportReason1 = "This product contains inappropriate contents.";
  const reportReason2 = "This product contains content involving the exploitation of minors, sexually explicit material, or offensive content.";
  const reportReason3 = "This product contains fraudulent content.";
  const tutorialData = useSelector((state) => state.tutorial.tutorialData);
  const outletRef = useRef();

  let myInterests = "";

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMi slice'
    }
  }

  const manageSwipe = (e) => {
    if ((swipeXStartPosition - e.changedTouches[0].clientX) > 150) {
      iLikeIt()
    }
    if ((swipeXStartPosition - e.changedTouches[0].clientX) < -150) {
      iDontCare()
    }
  };

  const calculateRemainingTime = () => {
    if (outletUserData.length > 0) {
      const seconds = (new Date().getTime() - new Date(outletUserData[0].resetOutletTime).getTime()) / 1000;
      const myPercentage = Math.floor((seconds / 28800) * 100);
      const myRemainingTime = Math.round((28800 - seconds) / 36) / 100
      setRemainingPercentage(myPercentage);
      setRemainingTime(myRemainingTime);
    }
  };

  const asyncManagement = () => {

    dispatch(setOutletData([...outletData].slice(1)))
    dispatch(updateOutletUserData([payloadOutletGlobal]))
    if (!outletData.length) { calculateRemainingTime(); setWaitForTimeReset(true) }

  };

  useEffect(() => {
    if (triggerAnimDontCare || triggerAnimLike) {
      setTimeout(() => {
        asyncManagement();
        setTriggerAnimDontCare(false);
        setTriggerAnimLike(false)
      }, 800)
    }
  }, [triggerAnimDontCare, triggerAnimLike]);

  const iDontCare = async () => {

    if (!outletUserData[0].resetOutletTime && outletData && outletData[counter]) {

      const myOutletData = outletData[counter];
      const payload = { id: myOutletData.id, iLikeIt: false };

      dispatch(updateAnnouncementFunc({ payload: payload, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch);
        });

      const myOutletUserData = outletUserData[0];
      const newOutletData = [];
      outletData.map((el) => {
        if (el.id !== outletData[counter].id) {
          newOutletData.push(el)
        }
      });
      const myNewOutletSetArray = `${newOutletData.map((el) => { return el.id })}`;
      //per pulire gli array da valori vuoti expl.[1,4,,3]
      let cleanHistoryArray = [];
      let cleanLikesArray = [];
      [...myOutletUserData.outletHistory.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanHistoryArray.push(el) } });
      [...myOutletUserData.outletLikes.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanLikesArray.push(el) } });

      const payloadOutletAsync = {
        ...myOutletUserData,
        idOwner: tkn.id,
        outletHistory: myOutletUserData.outletHistory.length > 0 ? [...cleanHistoryArray, outletData[counter].id].toString() : [outletData[counter].id].toString(),
        outletSet: myNewOutletSetArray.toString()
      }

      const payloadOutlet = {
        id: myOutletUserData.id,
        outletHistory: outletData[0].id,
        outletSet: outletData[0].id
      }

      if (outletData.length === 1) { //se è l'ultimo elemento
        setPayloadOutletGlobal({ ...payloadOutletAsync, resetOutletTime: new Date().toString() });
        dispatch(updateUserOutlet({ payload: payloadOutlet, token: token }))
          .then((res) => {
            serverResponseManagement(res, navigate, dispatch);
          })

      } else {//non è l'ultimo elemento
        setPayloadOutletGlobal(payloadOutletAsync);
        dispatch(updateUserOutlet({ payload: payloadOutlet, token: token }))
          .then((res) => {
            serverResponseManagement(res, navigate, dispatch);
          })
      }

      setTriggerAnimDontCare(true)

    }
  };

  const iLikeIt = async () => {

    if (!outletUserData[0].resetOutletTime && outletData && outletData[counter]) {

      if (tkn.id) {

        const myOutletData = outletData[counter];

        const cleanLikesIds = [];
        myOutletData.likesArry && [...myOutletData.likesArry.split(",")].map((el) => { if (+el !== tkn.id && el !== "" && el !== undefined && el !== "undefined") { return cleanLikesIds.push(el) } });
        const newLikes = myOutletData.newLikes && myOutletData.newLikes !== "null" ? myOutletData.newLikes.split(",").includes(tkn.id.toString()) ? [...myOutletData.newLikes.split(",")] : [...myOutletData.newLikes.split(","), tkn.id] : tkn.id
        const likesArray = [...cleanLikesIds, tkn.id];
        const payload = { id: myOutletData.id, iLikeIt: true, likesArry: tkn.id, newLikes: tkn.id }

        dispatch(updateAnnouncementFunc({ payload: payload, token: token }))
          .then((res) => {
            serverResponseManagement(res, navigate, dispatch);
          });

        const myOutletUserData = outletUserData[0];
        const newOutletData = []
        outletData.map((el) => {
          if (el.id !== outletData[counter].id) {
            newOutletData.push(el)
          }
        });
        const myNewOutletSetArray = `${newOutletData.map((el) => { return el.id })}`;
        //per pulire gli array da valori vuoti expl.[1,4,,3]
        let cleanHistoryArray = [];
        let cleanLikesArray = [];
        [...myOutletUserData.outletHistory.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanHistoryArray.push(el) } });
        [...myOutletUserData.outletLikes.split(",")].map((el) => { if (+el !== outletData[counter].id && el !== "" && el !== undefined && el !== "undefined") { return cleanLikesArray.push(el) } });

        const payloadOutletAsync = {
          ...myOutletUserData,
          idOwner: tkn.id,
          outletLikes: myOutletUserData.outletLikes.length > 0 ? [...cleanLikesArray, outletData[counter].id].toString() : [outletData[counter].id].toString(),
          outletSet: myNewOutletSetArray.toString()
        }

        const payloadOutlet = {
          id: myOutletUserData.id,
          outletLikes: outletData[0].id,
          outletSet: outletData[0].id
        }

        if (outletData.length === 1) { //se è l'ultimo elemento
          setPayloadOutletGlobal({ ...payloadOutletAsync, resetOutletTime: new Date().toString() });
          dispatch(updateUserOutlet({ payload: payloadOutlet, token: token }))
            .then((res) => {
              serverResponseManagement(res, navigate, dispatch);
            })

        } else {//non è l'ultimo elemento
          setPayloadOutletGlobal(payloadOutletAsync);
          dispatch(updateUserOutlet({ payload: payloadOutlet, token: token }))
            .then((res) => {
              serverResponseManagement(res, navigate, dispatch);
            })
        }

        setTriggerAnimLike(true);
        dispatch(setReloadFavouritesData(true));

      } else {
        navigate('/login')
      }

    }
  };

  const manageReport = () => {

    dispatch(reportUserFunc({
      payload: {
        idOwner: tkn.id,
        idUserReported: outletData[counter].idOwner,
        idReportedAnnouncement: outletData[counter].id,
        reasons: reportReason
      },
      token: token
    }))
      .then((res) => {
        serverResponseManagement(res, navigate, dispatch);
        if (res.payload.statusCode === 201) {
          setReportSuccessfullyReported(true);
          setTimeout(() => {
            setOpenReportModal(false);
            setReportSuccessfullyReported(false);
            iDontCare()
          }, 2000)
        }
      })
  }

  useEffect(() => { // step 1

    if (token && tkn.id) {

      dispatch(getSingleUserFunc({ id: tkn.id, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch);
          if (res.payload && res.payload.statusCode === 200 && res.payload.data && !res.payload.data[0].accountActive) { navigate('/account') };
        })

      dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch);
          if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length === 0) { navigate("/") }
        })
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {// step 2
    if (outletUserData.length !== 0) {
      myInterests = tkn ? tkn.interests : null;

      if (!outletUserData[0].outletSet && !outletUserData[0].resetOutletTime) {//#2 se l'outlet set di questo utente NON contiene degli id e non è presente il reset time. Interroga Angelina e ricarica l'outletSet
        setCounter(0);
        const myOutletHistory = outletUserData[0].outletHistory ? outletUserData[0].outletHistory : "0";
        const myOwnInterests = myInterests ? myInterests : "0";
        dispatch(getAnnouncementsByInterestsFunc({ idUser: tkn.id, interests: myOwnInterests, outletHistory: myOutletHistory, token: token }))
          .then((res) => {
            serverResponseManagement(res, navigate, dispatch);
            if (res.payload && res.payload.statusCode === 200 && res.payload.count && res.payload.count !== 0) { window.location.reload() }
          })

      } else if (outletUserData[0].resetOutletTime) {//se è presente il tempo di reset
        setWaitForTimeReset(true);
        const seconds = (new Date().getTime() - new Date(outletUserData[0].resetOutletTime).getTime()) / 1000;
        const myRemainingTime = Math.round((28800 - seconds) / 36) / 100

        if (myRemainingTime <= 0) {//ripristina l'outlet allo scadere del timer
          dispatch(updateUserOutlet({ payload: { id: outletUserData[0].id }, token: token }))
            .then((res) => {
              serverResponseManagement(res, navigate, dispatch);
              if (res.payload && res.payload.statusCode === 200) { window.location.reload() }
            })
        }

      } else {//#2
        if (!outletData.length) {
          dispatch(getAnnouncementsByIdFunc({ idSet: outletUserData[0].outletSet, token: token }))
            .then((res) => {
              serverResponseManagement(res, navigate, dispatch);
            })
        }
      }

    }
  }, [outletUserData]);

  useEffect(() => {//set time left
    calculateRemainingTime()
  }, [waitForTimeReset]);

  useEffect(() => {//switch tra tutoria like e tutorial trash
    setTimeout(() => {
      setTutorialLike(!tutorialLike)
    }, 3400)
  }, [tutorialLike]);

  useEffect(() => {

    if (outletUserData.length && !outletUserData[0].outletHistory) {
      setTutorial(true);
    } else {
      setTutorial(false)
    }

  }, [outletUserData]);

  useEffect(() => {
    outletRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  //Tutorial Functions
  const updateTutorial = () => {
    dispatch(updateTutorialFunc({ payload: { market: 0 }, token }))
      .then((res) => {
        if (res.payload.statusCode === 200) {
          window.location.reload()
        }
      })
  };

  useEffect(() => {
    if (tutorialData && tutorialData.market) {
      setTutorialMarket(true)
    }
  }, [tutorialData]);


  return (
    <>
      {
        waitingForAngelina ?
          <div className='w-100' style={{ position: "fixed", height: "100%", zIndex: "103", backgroundColor: "rgb(35,35,35,.8)" }}>
            <div style={{ minHeight: "calc(100vh - 65px)" }} className='position-relative py-5 px-3 d-flex justify-content-start flex-column'>
              <div className="position-relative w-100 myMaxW500" style={{ left: "50%", transform: "translate(-50%, 50%)" }}>
                <Lottie animationData={BoxFalling} options={defaultOptions} />
              </div>
            </div>
          </div>
          : null
      }
      {
        triggerAnimDontCare ?
          <div className='position-relative myZindex5'>
            <div className="position-fixed d-flex justify-content-center w-100" style={{ height: "100vh", background: "rgb(30, 30, 30, .9" }}>
              <Lottie className='myMaxW150 w-100 me-3' animationData={idontcareAnim} options={defaultOptions} style={{ left: "-400px" }} />
            </div>
          </div>
          : null
      }
      {
        triggerAnimLike ?
          <div className='position-relative myZindex5'>
            <div className="position-fixed d-flex justify-content-center w-100" style={{ height: "100vh", background: "rgb(30, 30, 30, .9" }}>
              <Lottie className='myMaxW200' animationData={ilikeitAnim} options={defaultOptions} style={{ left: "-400px" }} />
            </div>
          </div>
          : null
      }

      {/* inzio */}



      {
        waitForTimeReset ?
          <div className='myMinVh100 d-flex flex-column myBgAbsBlack align-items-center' ref={outletRef} style={{ paddingBottom: `${width > 1000 ? "180px" : "70px"}` }} >
            <div className='w-100 px-2 pt-5 text-center text-light'>
              <h3 className='myYellowColor px-2 mb-3 text-center'>The Market needs to recharge its energy!</h3>
              <h5 className='text-center fw-light'>Time left for a full charge</h5>
              <div className='p-4 pb-4 d-flex justify-content-center align-items-center align-items-center'>
                <i className="bi bi-lightning-charge-fill myYellowColor"></i>
                <div className='myBgDarkGray rounded-5 mx-3 myMaxW1000 w-100' style={{ height: "7px" }}>
                  <div className='myBgYellow rounded-5 position-relative h-100' style={{ width: `${remainingPercentage <= 100 ? remainingPercentage < 5 ? "5" : remainingPercentage : "100"}%` }}>
                    <div className={`h-100 rounded-5 ${remainingPercentage > 5 ? "percentageBarGlow" : ""} top-0 position-absolute`} style={{ width: "5vw", zIndex: 9 }}></div>
                  </div>
                </div>
                <h5 className='noBreak'>{remainingPercentage <= 100 ? remainingPercentage : "100"}%</h5>
              </div>
              <h5 >Time left:
                <b className='ms-3'>
                  {remainingTime < 1 ? null : `${Math.floor(remainingTime < 0 ? 0 : remainingTime)} hours`} {Math.floor((remainingTime < 0 ? 0 : remainingTime) * 60) % 60} minutes
                </b>
              </h5>
              <div className='d-flex justify-content-center'>
                <OutletTimerExplanation />
              </div>
            </div>
          </div>
          :
          <div className='myMinVh100 position-relative d-flex flex-column myBgAbsBlack align-items-center' ref={outletRef} style={{ paddingBottom: `${width > 1000 ? "180px" : "70px"}` }} onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageSwipe(e) }}>
            {
              outletData && width > 1000 && !waitForTimeReset ?
                <div className='position-fixed w-100 d-flex justify-content-center align-items-center py-1' style={{ bottom: "55px", zIndex: "2", backgroundColor: "rgb(10,10,10,.8)" }}>
                  <div className='w-100 d-flex justify-content-between myMaxW700 pb-2 pt-1'>
                    <i className={`bi bi-trash3-fill myIconLg text-light myCursor ms-5 ${tutorial ? "jumpingAnimation" : ""}`} onClick={() => iDontCare()}></i>
                    <i className={`bi bi-heart-fill myIconLg myFucsiaRed myCursor me-5  ${tutorial ? "pulsingAnimation" : ""}`} onClick={() => iLikeIt()}></i>
                  </div>
                </div>
                : null
            }
            {error ? <div className='mb-3 bg-danger text-light p-3 px-4'>{error}</div> : null}

            <div className='w-100'>
              {
                outletData && !waitForTimeReset ?
                  <div className='d-flex align-items-center justify-content-center gap-2 px-4 mt-3'>
                    <i className="bi bi-lightning-charge-fill myYellowColor"></i>
                    <div className='myBgDark rounded myMaxW700 w-100'>
                      <div className={"myBgYellow rounded"} style={{ height: "5px", width: `${outletData.length * outletEnergyCount}%` }}></div>
                    </div>
                  </div>
                  :
                  null
              }
            </div>

            {
              tutorialMarket ?
                <div className='position-fixed myBgTransparentVeryHigh start-0 d-flex justify-content-center w-100 myZindex2 myOverflowY myMaxVh100 pb-5'>
                  <div className='text-light border border-secondary shadow myBgTransparentVeryHigh top-0 m-3 rounded-5 p-3 my-5 text-center h-100 d-flex flex-column gap-5 myMaxW500'>
                    <TutorialMarket step={tutorialMarketStep} />
                    {
                      tutorialMarketStep === 0 ?
                        <Button className='rounded-5 w-100' onClick={() => { setTutorialMarketStep(1) }}>Next</Button>
                        :
                        <div className='d-flex gap-2'>
                          <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialMarketStep(0) }}>Back</Button>
                          <Button className='rounded-5 w-75' disabled={isLoadingTutorial ? true : false} onClick={updateTutorial}>Next {isLoadingTutorial ? <Spinner variant='light' animation='border' size='sm' /> : null}</Button>
                        </div>
                    }
                  </div>
                </div>
                : null
            }

            {
              outletData && outletData[counter] || isLoadingGetAnnouncements || isLoadingTutorial || isLoadingStore || isLoadingUser || isLoadingOutlet || isLoadingChat ?
                <CardAnnouncement singleData={[outletData[counter]]} isLoading={isLoadingGetAnnouncements} />
                : <h3 className='text-secondary mt-5 px-3 text-center'>Products will be available in a few days</h3>
            }

            {
              openReportModal || isLoadingGetAnnouncements || (outletData && !outletData[counter]) ?
                null
                : <div className='d-flex justify-content-center pt-5 myMidGrayColor myCursor' onClick={() => setOpenReportModal(true)}><i className="bi bi-exclamation-lg px-4 border border-secondary rounded-5">report this product</i></div>
            }

            {
              openReportModal && !isLoadingGetAnnouncements ?
                <div className='position-fixed w-100 d-flex flex-column align-items-center top-0 p-3 myVh100 myBgTransparentVeryHigh'>
                  <div className='rounded-5 myMaxW500 text-light w-100 myBgDark444 px-3 py-5 mt-3'>
                    {
                      reportSuccessfullyReported ?

                        <div>
                          <h5 className='myChatColor mb-3 text-center'> Report correctly sended!</h5>
                          <p>We've received your report and will review it to ensure the product meets all legal requirements. Thank you for your feedback!</p>
                          <div className='p-4 pt-0 d-flex flex-column align-items-center'>
                            <div className='rounded mt-2 myBgDarkGray mx-3 myMaxW700 w-100'>
                              <div className={"myBgChat rounded percentageBar"} style={{ height: "6px" }}></div>
                            </div>
                          </div>
                        </div>
                        :
                        <>
                          <InputGroup className="mb-3 d-flex flex-column gap-3">
                            <div className='d-flex gap-2'><InputGroup.Checkbox className='m-0 myCursor' checked={reportReason === reportReason1} onChange={() => { setReportReason(reportReason1) }} aria-label="Checkbox for following text input" />{reportReason1}</div>
                            <div className='d-flex gap-2'> <InputGroup.Checkbox className='m-0 myCursor' checked={reportReason === reportReason2} onChange={() => { setReportReason(reportReason2) }} aria-label="Checkbox for following text input" />{reportReason2}</div>
                            <div className='d-flex gap-2'><InputGroup.Checkbox className='m-0 myCursor' checked={reportReason === reportReason3} onChange={() => { setReportReason(reportReason3) }} aria-label="Checkbox for following text input" />{reportReason3}</div>
                          </InputGroup>
                          <div className='d-flex justify-content-center gap-3'>
                            <Button className='rounded-5 px-4' disabled={!reportReason || isLoadingSendReport}
                              onClick={manageReport}
                            >Send {isLoadingSendReport ? <Spinner variant='light' size='sm' /> : null}</Button>
                            <Button className='rounded-5 px-4' variant='secondary' onClick={() => setOpenReportModal(false)}>Cancel</Button>
                          </div>
                        </>

                    }
                  </div>
                </div>
                : null
            }

            {
              tutorial && width && outletData && outletData[counter] <= 1000 &&
              <div className='myCursor' onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageSwipe(e) }}>
                {
                  tutorialLike ?
                    <div className='position-absolute start-0 h-100 w-100 top-0 myBgTransparentSpecial' >
                      <div className='position-absolute top-0 p-5 w-100 d-flex justify-content-center'><i className='bi bi-heart-fill myFucsiaRed myFontSizeTutorial'></i></div>
                      <Lottie className='top-0' animationData={SwipeRight} options={defaultOptions} />
                    </div>
                    :
                    <div className='position-absolute h-100 w-100 start-0 top-0 myBgTransparentSpecial' style={{ transform: "rotateY(180deg)", overflowX: "hidden" }}>
                      <div className='position-absolute top-0 w-100 p-5 d-flex justify-content-center'><i className='bi bi-trash-fill text-light myFontSizeTutorial'></i></div>
                      <Lottie className='top-0' animationData={SwipeRight} options={defaultOptions} />
                    </div>
                }
              </div>
            }

          </div>
      }

    </>
  )
}

export default _Store